import React, { useCallback, useState } from "react"

interface Props {
  title: string
  content: string
  collapsedByDefault?: boolean
  sectionId?: string
}

const CollapsibleDetailsSection: React.FC<Props> = ({ title, content, collapsedByDefault, sectionId }) => {

  const [collapsed, setCollapsed] = useState(collapsedByDefault)

  const toggleCollapse = useCallback(() => {
    setCollapsed((currentState) => !currentState)
  }, [])

  const classes = [
    "details__section",
    "details__section--collapsible",
    "details__section--" + ((collapsed) ? "collapsed" : "open"),
  ].join(" ")

  return  <div id={sectionId} className={classes}>
    <div className="details__subtitle" onClick={toggleCollapse}>
      <div className="details__subtitle__title">{title}</div>
      <div className="details__subtitle__arrow" />
    </div>
    <div
      className={[
        "details__section__collapsibleContent",
        collapsed ? "details__section__collapsibleContent--collapsed" : "",
      ].join(" ")}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  </div>
}

export default CollapsibleDetailsSection