import React, { useCallback, useState, useRef } from "react"
import useOnClickOutside from "use-onclickoutside"

interface Option {
  name: string
  url: string
  selected: boolean
  cancelled: boolean | null
}

interface Props {
  options: Option[]
}

const OpenedDropdown: React.FC<Props & {selectOption(o: Option): void, abort(): void, selected?: Option}> = ({options, selectOption, abort, selected}) => {
  return <div className="raceweekSelector__options">
    <div className="raceweekSelector__options__inner">
      {options.map((option, index) => {
        return (
          <div
            key={index}
            className={`raceweekSelector__options__item ${selected === option ? "raceweekSelector__options__item--selected" : ""}`}
            onClick={() => selectOption(option)}
          >
            <span>{option.name}</span>
          </div>
        )
      })}
    </div>
  </div>
}

const RaceweekSelector: React.FC<Props> = (props) => {
  const {options} = props
  const [open, setOpen] = useState(false)

  const ref = useRef<HTMLElement>()
  useOnClickOutside(ref as any, () => { if (open) setOpen(false) })

  const [selected, setSelected] = useState(
    options.find((option) => option.selected)
  )

  const selectOption = (option: Option) => {
    setOpen(false)
    setSelected(option)
    window.location.href = option.url
  }

  return <div ref={ref as any} className="raceweekSelector">
    <button
      className={[
        "raceweekSelector__button",
        open ? "raceweekSelector__button--open" : "",
      ].join(" ")}
      aria-expanded={open}
      onClick={() => setOpen(val => !val)}
    >
      <div className="raceweekSelector__button__text">
        {selected?.name}
      </div>
      <div className="raceweekSelector__button__arrow" />
    </button>
    {open && <OpenedDropdown {...props} selectOption={selectOption} abort={() => setOpen(false)} selected={selected} />}
  </div>
}

export default RaceweekSelector
