// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '../polyfills/forEach'

import 'postcss-normalize'

import '../styles/app'
import '../tracking/boot'

import '../misc/text_direction_helper'

import '../reset-filter-communities'
import '../toggle_ranking_buttons'

import './mountComponentsOnRenderAsyncLoad'

import { initialize as initializeTheme } from '../misc/theme'
import { initialize as initializeOpenViaPOST } from '../misc/openViaPOST'

import { initialize as initializeGigya} from  '../gigya/integration'

// pack all images
const imageContext = require.context('../images', true, /.(png|jpg|svg)$/)
imageContext.keys().forEach((key) => { imageContext(key) })

initializeGigya()

initializeTheme()
initializeOpenViaPOST()

const I18n = require('i18n-js')
I18n.defaultLocale = window.__localization.locale
I18n.locale = window.__localization.locale
I18n.translations = window.__localization.translations

import moment from 'moment'
moment.locale(window.__localization.locale)

require('@rails/ujs').start()
// Support component names relative to this directory:
var componentRequireContext = require.context('components', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)
