import React, { PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from 'react'
import _ from "lodash"

// @ts-ignore
import { topNavigationClick } from '@root/tracking/signals'
import { useDevice } from '@root/misc/device'
import logoSrc from "@images/appLogo.jpg"
import iconSignIn from "@images/iconSignIn.png"
import iconRegister from "@images/iconAccount.png"
import iconShop from "@images/iconShop.png"
import iconTickets from "@images/iconTicket.png"
import iconArrowRight from "@images/iconArrowRight.png"
import Button from './Button'
import I18n from 'i18n-js'
import { disableBodyScroll, enableBodyScroll } from '@root/misc/disableBodyScroll'
import useOnClickOutside from 'use-onclickoutside'
import Icon from './Icon'

interface NaviProps {
  items: {url: string, name: string, active?: boolean, target?: string}[]
  profileInfo: ProfileInfo
  authEndpoints: AuthEndpoints
  prizesUrl: string
}

interface ProfileInfo {
  nickname: string
  profileSettingsPath: string
  loggedIn: boolean
}

interface AuthEndpoints {
  login_path: string
  login_target: string
  register_path: string
  register_target: string
  logout_path: string
  logout_target: string
}

type NaviPropsWithBurgerControl = NaviProps & { burgerOpen: boolean, setBurgerOpen(val: boolean): void }

const UserCircle: React.FC<{profileInfo: ProfileInfo}> = ({profileInfo}) => {
  return <a href={profileInfo.profileSettingsPath} target="_blank" className="navi__userCircle" title={profileInfo.nickname}><span>{profileInfo.nickname[0] || ""}{profileInfo.nickname[1] || ""}</span></a>
}

const handleLogin = (e: MouseEvent) => {
    e.preventDefault()
    __Neo.gigya.login()
}

const MobileMenu: React.FC<NaviPropsWithBurgerControl> = ({items, profileInfo, authEndpoints, burgerOpen, setBurgerOpen, prizesUrl}) => {
  return <div className="navi__mobileMenu">
    <div className="navi__mobileMenu__topArea">
      <div>
        <div className="navi__mobileMenu__burgerIconHolder">
          <BurgerIcon isOpen={burgerOpen} onClick={(() => setBurgerOpen(burgerOpen ? false : true))} />
        </div>
      </div>
      <div>
        {profileInfo.loggedIn
          ? <UserCircle profileInfo={profileInfo} />
          : <>
              <Button icon={iconRegister} title={I18n.t("account_section.two_buttons.register")} target={authEndpoints.register_target} href={authEndpoints.register_path} />
              <Button icon={iconSignIn} primary title={I18n.t("navigation.login")} onClick={handleLogin} />
            </>}
      </div>
    </div>
    <div className="navi__mobileMenu__contentArea">
      <div className="navi__mobileMenu__contentArea__inner">
        <div className="navi__mobileMenu__navLinks">
          {_.map(items, (item, index) => {
            return <NaviLink key={index} item={item} />
          })}
        </div>
        <div className="navi__mobileMenu__prizeHolder">
          <Prize num={1} prizesUrl={prizesUrl} />
        </div>
        <div className="navi__mobileMenu__bottomLinks">
          <Button icon={iconShop} title={I18n.t("navigation.liv_shop")} href={I18n.t("navigation.liv_shop_url")} target='_blank'/>
          <Button icon={iconTickets} title={I18n.t("navigation.liv_tickets")} href={I18n.t("navigation.liv_tickets_url")} target='_blank'/>
        </div>
      </div>
    </div>
  </div>
}

const PhoneAndTabletNavi: React.FC<NaviPropsWithBurgerControl> = (props) => {
  const {items, profileInfo, authEndpoints, burgerOpen, setBurgerOpen} = props
  const { isPhone } = useDevice()

  return <>
    <TopBar
      left={<>
        <BurgerIcon isOpen={burgerOpen} onClick={(() => setBurgerOpen(burgerOpen ? false : true))} />
      </>}
      right={<>
        {profileInfo.loggedIn
          ? <UserCircle profileInfo={profileInfo} />
          : <Button primary onlyIcon icon={iconSignIn} title={I18n.t("navigation.login")} onClick={handleLogin} />}
      </>}/>
    <div className="navi__mobileStaticNavLinks">
      {_.map(items, (item, index) => {
        if (index > 3) { return null }
        return <NaviLink key={index} item={item} smallerText={isPhone} />
      })}
    </div>
    {burgerOpen && <MobileMenu {...props} />}
  </>
}

const Prize: React.FC<{num: number, prizesUrl: string}> = ({num, prizesUrl}) => {
  return <div className={`navi__prize navi__prize--${num}`}>
    <div className="navi__prize__box">
      <p className="navi__prize__section">{I18n.t("navigation.teaser_prizes.title")}</p>
      <h3>{I18n.t(`event_prizes.prize${num}.title`)}</h3>
      <p className="navi__prize__info">{I18n.t(`event_prizes.prize${num}.info`)}</p>
      <p><a className="neo__linkWithIcon" href={prizesUrl}>
        {I18n.t("navigation.teaser_prizes.more_link")}
        <Icon src={iconArrowRight} />
      </a></p>
    </div>
  </div>
}

const DesktopMenu: React.FC<NaviPropsWithBurgerControl> = ({items, profileInfo, authEndpoints, burgerOpen, setBurgerOpen, prizesUrl}) => {
  const ref = useRef<HTMLElement>()
  useOnClickOutside(ref as any, () => setBurgerOpen(false))

  return <div ref={ref as any} className="navi__desktopMenu">
    <div className="navi__desktopMenu__topArea">
      <div className="navi__desktopMenu__topLinksArea">
        <div className="navi__desktopMenu__burgerIconHolder">
          <BurgerIcon isOpen={burgerOpen} onClick={(() => setBurgerOpen(burgerOpen ? false : true))} />
        </div>
        <div className="navi__desktopMenu__navLinks">
          {_.map(items, (item, index) => {
            return <NaviLink key={index} item={item} />
          })}
        </div>
      </div>
      <div className="navi__desktopMenu__topExtraContentArea">
        <Prize key={1} num={1} prizesUrl={prizesUrl} />
        <Prize key={2} num={2} prizesUrl={prizesUrl} />
        <Prize key={3} num={3} prizesUrl={prizesUrl} />
      </div>
    </div>
    <div className="navi__desktopMenu__bottomArea">
      <div className="navi__desktopMenu__externalLinkArea">
        <Button icon={iconShop} title={I18n.t("navigation.liv_shop")} href={I18n.t("navigation.liv_shop_url")} target='_blank'/>
        <Button icon={iconTickets} title={I18n.t("navigation.liv_tickets")} href={I18n.t("navigation.liv_tickets_url")} target='_blank'/>
      </div>
      <div className="navi__desktopMenu__accountLinksArea">
        {profileInfo.loggedIn
          ? <UserCircle profileInfo={profileInfo} />
          : <>
              <Button icon={iconRegister} title={I18n.t("account_section.two_buttons.register")} target={authEndpoints.register_target} href={authEndpoints.register_path} />
              <Button icon={iconSignIn} primary title={I18n.t("navigation.login")} onClick={handleLogin} />
            </>}
      </div>
    </div>
  </div>
}

const DesktopNavi: React.FC<NaviPropsWithBurgerControl> = (props) => {
  const {items, profileInfo, authEndpoints, burgerOpen, setBurgerOpen} = props
  const {isDesktopLargeUp} = useDevice()

  return <>
    <TopBar
      left={<>
        <BurgerIcon isOpen={burgerOpen} onClick={(() => setBurgerOpen(burgerOpen ? false : true))} />
        {_.map(items, (item, index) => {
          if ((!isDesktopLargeUp && index > 2) || index > 3) { return null }
          return <NaviLink key={index} item={item} />
        })}
      </>}
      right={<>
        <Button icon={iconShop} title={I18n.t("navigation.liv_shop")} href={I18n.t("navigation.liv_shop_url")} target='_blank'/>
        <Button icon={iconTickets} title={I18n.t("navigation.liv_tickets")} href={I18n.t("navigation.liv_tickets_url")} target='_blank'/>
        {profileInfo.loggedIn
          ? <UserCircle profileInfo={profileInfo} />
          : <Button icon={iconSignIn} primary title={I18n.t("navigation.login")} onClick={handleLogin} />}
      </>}/>
    {burgerOpen && <DesktopMenu {...props} />}
  </>
}

const BurgerIcon: React.FC<{isOpen: boolean, onClick(): void}> = ({isOpen, onClick}) => {
  const className = [
    "navi__burger",
    isOpen ? "navi__burger--open" : "navi__burger--closed",
  ].join(" ")

  return <a
    className={className}
    onClick={onClick} />
}

const TopBar: React.FC<{left?: React.ReactNode, right?: React.ReactNode}> = ({left, right}) => {
  return <div className="navi__topBar">
    <div className="navi__topBar__left">{left}</div>
    <div className="navi__topBar__logo"><img src={logoSrc} /></div>
    <div className="navi__topBar__right">{right}</div>
  </div>
}

const NaviLink: React.FC<{item: NaviProps["items"][number], smallerText?: boolean}> = ({item, smallerText}) => {
  const className = [
    "navi__navLink",
    item.active ? "navi__navLink--active" : "",
    smallerText ? "navi__navLink--smaller" : "",
  ].join(" ")

  const additionalProps = {
    ...topNavigationClick.trackingProps({ name: item.name }),
    ...(item.target ? { target: item.target } : {})
  }
  return <a className={className} href={item.url} {...additionalProps}>
    <span>{item.name}</span>
  </a>
}

const Navi: React.FC<NaviProps> = (props) => {
  const { isDesktopUp } = useDevice()
  const [burgerOpen, setBurgerOpen] = useState(false)

  useEffect(() => {
    if (burgerOpen) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }, [burgerOpen])

  useEffect(() => {
    return () => { // make sure bodyscroll enabled after unmount!
      enableBodyScroll();
    }
  }, [])

  return isDesktopUp
    ? <DesktopNavi {...props} burgerOpen={burgerOpen} setBurgerOpen={setBurgerOpen} />
    : <PhoneAndTabletNavi {...props} burgerOpen={burgerOpen} setBurgerOpen={setBurgerOpen} />
}

export default Navi
