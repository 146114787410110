import { useDevice } from "@root/misc/device";
import I18n from "i18n-js"
import React from "react"
import Slider, {Settings as SliderSettings} from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useWindowSize } from "@root/misc/useWindowSize";
import _ from "lodash";
// @ts-ignore
import { maxDesktopContentWidth } from "@styles/_export"
import useCountdown from "@root/misc/useCountdown";
import Button from "../Button";
import { unwrapSec } from "../SquadBet/helper";

const XSlider = Slider as any // otherwise type error in react

const flagImgContext = require.context("@images/flags", true, /.*\.svg/)
const flagSrc = (countryCode: string) => {
  try {
    return flagImgContext(`./${countryCode}.svg`)
  } catch(err) {
    return ""
  }
}
// round_summary.state_upcoming
// round_summary.btn_review_team
interface RoundSummary {
  title: string // "current event", "last event"
  round_title: string // "DALLAS"
  round_subtitle: string // 2025-09-xx | Spain
  points: number
  bets_path: string // "/round/14"
  date: string // localized, e.g "2025-09-20"
  is_current_round: boolean // true
  missing_bets: number // 0
  num_open_events: number // 3
  country_flag?: string // "DE"
  warn_missing_bets: false
  state: "open" | "live" | "upcoming" | "over"
  countdown_sec?: number
}

const RoundSummaryView: React.FC<{summary: RoundSummary}> = ({summary}) => {
  return <div className="roundSummary">
    <h3>{summary.round_title}</h3>
    {summary.country_flag && flagSrc(summary.country_flag) !== ""
      ? <div className="roundSummary__flag">
          <div style={{backgroundImage: `url(${flagSrc(summary.country_flag)})`}} />
        </div>
      : <div className="roundSummary__flagPlaceholder" />}
    <div className="roundSummary__info">
      <p>{summary.round_subtitle}</p>
      {summary.state &&
        <p className={`roundSummary__state roundSummary__state--${summary.state}`}>
          {summary.state === "open" && _.isNumber(summary.countdown_sec)
            ? <CountdownText initialSec={summary.countdown_sec} />
            : <span>{I18n.t(`round_summary.state_${summary.state}`)}</span>}
          {summary.state === "live" || summary.state === "over" &&
            ` | ${I18n.t("general.points_with_label", {points: summary.points || 0})}`}
        </p>}
    </div>
    <Button
      disabled={summary.state === "upcoming"}
      href={summary.bets_path}
      primary={summary.state === "open"}>{roundButtonLabel(summary)}</Button>
  </div>
}

const roundButtonLabel = (summary: RoundSummary) => {
  if (summary.state === "open") { return I18n.t("round_summary.btn_set_bets")}
  if (summary.state === "live") { return I18n.t("round_summary.btn_review_team")}
  if (summary.state === "over") { return I18n.t("round_summary.btn_review_team")}
  return I18n.t("round_summary.btn_set_bets")
}

export const countdownSegmentsToComp = (segments: {d: string, hh: string, mm: string, ss: string}) => {
  const {d, hh, mm, ss} = segments
  return <>
    {`${d}${I18n.t("general.countdown_short_days")} : `}
    {`${hh}${I18n.t("general.countdown_short_hour")} : `}
    {`${mm}${I18n.t("general.countdown_short_minutes")} : `}
    {`${ss}${I18n.t("general.countdown_short_seconds")}`}
  </>
}

export const CountdownText: React.FC<{initialSec: number}> = ({initialSec}) => {
  const segments = useCountdown({secondsLeft: initialSec})
  return countdownSegmentsToComp(segments)
}

const RoundSummaries: React.FC<{summaries: {table: RoundSummary}[]}> = ({summaries}) => {
  const {isPhone, isDesktopUp, isDesktopLargeUp} = useDevice()
  const {width} = useWindowSize()

  const sliderSettings: SliderSettings = {
    dots: true,
    variableWidth: true,
    infinite: false,
    arrows: false,
  }

  const slideWidth = isPhone
    ? `${Math.min(width - (summaries.length === 1 ? 0 : 42), 400)}px`
    : `${Math.min(width, parseInt(maxDesktopContentWidth)) / (summaries.length > 0 && summaries.length < 3 ? summaries.length : 3) - 8}px` // tablet + desktop

  return <div className="roundSummaries__block">
    <div className="layout__container">
      <h2 className="roundSummaries__title">{I18n.t("dash.next_events.title")}</h2>
    </div>
    <div className="roundSummaries">
      <XSlider {...sliderSettings}>
        {_.map(summaries, (s, index) => {
          return <div key={index} style={{width: slideWidth, position: "relative"}}>
            <div className="roundSummary__holder">
              <RoundSummaryView summary={s.table} />
            </div>
          </div>
        })}
      </XSlider>
    </div>
  </div>
}

export default RoundSummaries
