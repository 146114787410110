/*
  Sync structure with rails "button" partial!
*/

import React, { MouseEventHandler, PropsWithChildren } from 'react'

interface Props {
  primary?: boolean
  rounded?: boolean
  disabled?: boolean
  forceWhite?: boolean
  noMinWidthOnMobile?: boolean
  className?: string
  href?: string
  onClick?: (e: any) => void
  title?: string
  target?: string
  method?: string
  icon?: string
  onlyIcon?: boolean
  iconPos?: "start" | "end"
}

const Button: React.FC<PropsWithChildren<Props>> = ({
  className,
  primary,
  disabled,
  href,
  onClick,
  children,
  title,
  target,
  method,
  icon,
  onlyIcon,
  iconPos,
  noMinWidthOnMobile,
}) => {
  let variant = 'secondary'
  if (primary) variant = 'primary'

  const methodAttributes = method ? { "data-method": method, rel: "nofollow" } : {}
  const targetAttr = target ? { target: target } : {}
  const classNames = [
    "button",
    `button--${variant}`,
    onlyIcon ? 'button--onlyIcon' : "",
    noMinWidthOnMobile ? "button--noMinWidthOnMobile" : "",
    className || "",
  ].join(" ")

  const Inner = <>
    <span className="button__bg" />
    <span className="button__highlight" />
    {icon && iconPos !== "end" &&
      <img className='button__icon' src={icon} />}
    <span className='button__title'>
      {title || children}
    </span>
    {icon && iconPos === "end" &&
      <img className='button__icon' src={icon} />}
  </>

  if (disabled) {
    return (
      <span className={classNames}>
        {Inner}
      </span>
    )
  } else {
    return (
      <a className={classNames} href={href} onClick={onClick} {...targetAttr} {...methodAttributes}>
        {Inner}
      </a>
    )
  }
}

export default Button
