import I18n from "i18n-js"
import React from "react"

interface TopRank {
  community_id: number
  event_points: number | null
  global_points: number | null
  member_count: number
  name: string
  rank: number
}

const Rank = ({
  rank,
  name,
  event_points: eventPoints,
  global_points: globalPoints,
  member_count: memberCount,
}: TopRank) => {
  return <div className="top3Ranks__item">
    <div className="top3Ranks__item__topContainer">
      <div className="top3Ranks__item__rank">{rank}</div>
      <div className="top3Ranks__item__info">
        <div className="top3Ranks__item__infoName">{name}</div>
        <div className="top3Ranks__item__infoMemberCount">
          {I18n.t("ranking.top_3.members", { count: memberCount })}
        </div>
      </div>
    </div>
    <div className="top3Ranks__item__divider" />
    <div className="top3Ranks__item__bottomContainer">
      <div className="top3Ranks__item__teamAndScore">
        <div className="top3Ranks__item__scoreContainer">
          <div className="top3Ranks__item__scoreTitle">{I18n.t("ranking.top_3.event_score")}</div>
          <div className="top3Ranks__item__score">{eventPoints ?? 0}</div>
        </div>
        <div className="top3Ranks__item__scoreContainer">
          <div className="top3Ranks__item__scoreTitle">{I18n.t("ranking.top_3.global_score")}</div>
          <div className="top3Ranks__item__score">{globalPoints ?? 0}</div>
        </div>
      </div>
    </div>
  </div>
}

interface Props {
  top_3: TopRank[]
}

const Top3Ranks = ({ top_3: top3 }: Props) => {
  return <div className="top3Ranks__container">
    {top3.map((rank) => {
      return <Rank {...rank} />
    })}
  </div>
}

export default Top3Ranks
