import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import I18n from 'i18n-js'
import Button from './Button'

import modalSaveZone from '../misc/modal_save_zone'

const KickoutMemberModal = ({ isOpen, onClose, userName, kickoutMemberPath }) => {
  const [ saveZoneReady, setSaveZoneReady ] = useState(null)

  useEffect(() => {
    modalSaveZone.onReady(() => {
      setSaveZoneReady(true)
    })
  }, [false])

  if (!saveZoneReady) return (null)

  const saveZone = modalSaveZone.calculate()

  const style = {
    // width: `${Math.min(400, saveZone.width)}px`,
    top: `${saveZone.centerY}px`,
    left: `${saveZone.centerX}px`,
    transform: `translate(-50%, -50%)`
  }

  return (
    <Modal
      isOpen={isOpen}
      appElement={document.querySelector('#neoApp')}
      onRequestClose={() => onClose()}
      parentSelector={() => document.getElementById("neoApp")}
      overlayClassName="neo__modalOverlay"
      style={{
        content: style
      }}
      className='invitedModal'>
      <div className='invitedModal__close' onClick={() => onClose()} />
      <div className='invitedModal__header'>
        <h2 className='invitedModal__title invitedModal__title--bold'>{I18n.t('community.kickout_member_modal.title')}</h2>
      </div>
      <div className='invitedModal__body'>
        <p className='invitedModal__text'>
          {I18n.t('community.kickout_member_modal.ask', { user_name: userName })}
        </p>
        <div className='invitedModal__actions' style={{ display: 'flex' }}>
          <Button className='invitedModal__button' primary href={kickoutMemberPath} method="delete">
            {I18n.t('community.kickout_member_modal.btn_confirm')}
          </Button>
          <Button role='button' onClick={() => onClose()} className='invitedModal__button' secondary>
            {I18n.t('community.kickout_member_modal.btn_abort')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default KickoutMemberModal
