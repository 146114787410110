import React, { useState } from 'react'
import KickoutMemberModal from './KickoutMemberModal'
import I18n from 'i18n-js'

import Button from './Button'

function KickoutMemberButton ({ user_name: userName, kickout_member_path: kickoutMemberPath, trash_icon: trashIcon }) {
  const [isOpen, setModalOpen] = useState(false)

  return (
    <React.Fragment>
      <KickoutMemberModal isOpen={isOpen} onClose={() => setModalOpen(false)} userName={userName} kickoutMemberPath={kickoutMemberPath} />
      <Button className="community__kickoutMemberButton" icon={trashIcon} onlyIcon onClick={() => setModalOpen(true)} />
    </React.Fragment>
  )
}

export default KickoutMemberButton
