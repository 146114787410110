import React from 'react'

const Icon: React.FC<{ type?: string, inlineWithText?: boolean, src?: string, black?: boolean, white?: boolean }> = ({type, inlineWithText, src, black, white}) => {
  const className = [
    "icon",
    type ? `icon--${type}` : "",
    inlineWithText ? "icon--inlineWithText" : "",
    black ? "icon--colorizeBlack" : "",
    white ? "icon--colorizeWhite" : "",
  ].join(" ")
  return <span className={className} style={src ? {backgroundImage: `url(${src})`} : undefined}/>
}

export default Icon
