// @ts-ignore
import cssVar from '@styles/_export'
import { useMediaQuery } from "react-responsive"

export const useDevice = () => {
  const isPhone = useMediaQuery({ query: `(max-width: ${parseInt(cssVar.breakpointTabletUp) - 0.4}px)`})
  const isDesktopUp = useMediaQuery({ query: `(min-width: ${parseInt(cssVar.breakpointDesktopUp)}px)`})
  const isDesktopLargeUp = useMediaQuery({ query: `(min-width: ${parseInt(cssVar.breakpointDesktopLargeUp)}px)`})

  return {
    isPhone,
    isTabletUp: !isPhone,
    isDesktopUp,
    isDesktopLargeUp,
  }
}
