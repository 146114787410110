import React, { useState, useCallback } from 'react'
import I18n from 'i18n-js'
import axios from 'axios'

import Button from "./Button"

const HAS_OPTIN = false

const redirectTo = (url) => {
  console.log('redirect to', url)

  if (url !== '') {
    window.location.href = url
    return true
  }

  return false
}

const submitToNeo = (submitUrl, authenticityToken, teamName, receiveBetReminders, reminderEmail, marketingOptIn) => {
  return new Promise((resolve, reject) => {
    const data = {
      authenticity_token: authenticityToken,
      team_name: teamName,
      receive_bet_reminders: receiveBetReminders,
      reminder_email: reminderEmail,
      marketing_opt_in: marketingOptIn,
    }

    axios.post(submitUrl, data).then(({data}) => {
      if (data?.error) {
        reject(data.error)
      } else {
        resolve()
      }
    }).catch((err) => {
      reject(err)
    })
  })
}

const NicknameForm = (
  {
    variant, // "complete_profile" | "edit_profile"
    teamName: defaultTeamName,
    terms_url: termsUrl,
    submit_url: submitUrl,
    redirect_after_submit_url: redirectAfterSubmitUrl,
    authenticity_token: authenticityToken,
    receive_bet_reminders: receiveBetReminders,
    reminder_email: defaultReminderEmail,
    marketing_opt_in: marketingOptIn,
  }) => {

  const [teamName, setTeamName] = useState(defaultTeamName || "")
  const [reminderEmail, setReminderEmail] = useState(defaultReminderEmail || "")
  const [optinChecked, setOptInChecked] = useState(false)

  const [optInRequiredError, setOptInRequiredError] = useState(false)
  const [teamNameErrorMsg, setTeamNameErrorMsg] = useState(false)
  const [emailErrorMsg, setEmailErrorMsg] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [receiveBetRemindersChecked, setReceiveBetRemindersChecked] = useState(receiveBetReminders || false)
  const [marketingOptInChecked, setMarketingOptInChecked] = useState(marketingOptIn || false)

  const submit = useCallback(() => {
    let earlyErrored = false

    if (!teamName) {
      setTeamNameErrorMsg(I18n.t('profiles.errors.team_name_missing'))
      earlyErrored = true
    } else {
      setTeamNameErrorMsg('')
    }

    if (HAS_OPTIN && !optinChecked) {
      setOptInRequiredError(true)
      earlyErrored = true
    } else {
      setOptInRequiredError(false)
    }

    if (!earlyErrored) {
      setSubmitting(true)
      submitToNeo(submitUrl,
          authenticityToken,
          teamName,
          receiveBetRemindersChecked,
          receiveBetRemindersChecked ? reminderEmail : "",
          marketingOptInChecked,
        ).then(() => {
          if (!redirectTo(redirectAfterSubmitUrl)) {
            // could not redirect?
            setSubmitting(false)
          }
        })
        .catch((error) => {
          switch(error.type) {
            case "profile":
              console.error(error.message)
              break
            case "email":
              setEmailErrorMsg(error.message)
              break
            case "team_name":
              setTeamNameErrorMsg(error.message)
              break
          }
          setSubmitting(false)
        })
    } else {
      console.log('early error -> don\'t submit anything')
    }
  }, [teamName, receiveBetRemindersChecked, reminderEmail, marketingOptInChecked, optinChecked])
  const onKeyDownEmail = useCallback((e) => {
    if (e.key === "Enter") {
      submit()
    }
  }, [teamName, receiveBetRemindersChecked, reminderEmail, marketingOptInChecked, optinChecked])

  const onChangeTeamName = useCallback((e) => {
    setTeamName(e.target.value)
  }, [])
  const onChangeEmail = useCallback((e) => {
    setReminderEmail(e.target.value)
  }, [])
  const onReceiveBetRemindersChange = useCallback((e) => {
    setReceiveBetRemindersChecked(e.target.checked)
  }, [])
  const onMarketingOptInChange = useCallback((e) => {
    setMarketingOptInChecked(e.target.checked)
  }, [])
  const onOptInChecked = useCallback((e) => {
    setOptInChecked(e.target.checked)
  }, [])

  return (
    <div>
      <h3>
        <label htmlFor={'team_name'}>{I18n.t('complete_profile.team_name_label')}</label>
      </h3>
      <p>
        <input
          type='text'
          name='team_name'
          id='team_name'
          value={teamName}
          onChange={onChangeTeamName}
          autoFocus
          maxLength={32}
          className={'input input--higher' + (teamNameErrorMsg ? ' input--error' : '')}
          placeholder={I18n.t('complete_profile.team_name_placeholder')} />
        {teamNameErrorMsg &&
          <span className='form__error form__error--centered'>{teamNameErrorMsg}</span>}
      </p>
      <p className='completeProfile__checkbox__container'>
        <span>
          <input
              className='checkbox__input'
              type='checkbox'
              name='receiveBetReminders'
              id='__receiveBetReminders'
              checked={receiveBetRemindersChecked}
              onChange={onReceiveBetRemindersChange}
          />
          <label className={'checkbox__label'} htmlFor={'__receiveBetReminders'} />
        </span>
        <span>
          <span dangerouslySetInnerHTML={{ __html: I18n.t('complete_profile.receive_bet_reminders') }} />
        </span>
      </p>
      {receiveBetRemindersChecked && <p>
          <input
              type='email'
              name='reminder_email'
              id='reminder_email'
              value={reminderEmail}
              onChange={onChangeEmail}
              onKeyDown={onKeyDownEmail}
              maxLength={64}
              className={'input input--higher' + (emailErrorMsg ? ' input--error' : '')}
              placeholder={I18n.t('complete_profile.email_placeholer')} />
          {emailErrorMsg &&
              <span className='form__error form__error--centered'>{emailErrorMsg}</span>}
        </p>}
      {HAS_OPTIN &&
        <p className='completeProfile__checkbox__container'>
          <span>
            <input
              className={'checkbox__input' + (optInRequiredError ? ' checkbox__input--error' : '')}
              type='checkbox'
              name='opt_in_terms'
              id='__optInTerms'
              checked={optinChecked}
              onChange={onOptInChecked}
            />
            <label className={'checkbox__label'} htmlFor={'__optInTerms'} />
          </span>
          <span>
            <span dangerouslySetInnerHTML={{ __html: I18n.t('complete_profile.terms_optin', { terms_url: termsUrl }) }} />
            {optInRequiredError &&
              <span className='form__error'>{I18n.t('profiles.errors.optin_terms_required')}</span>}
          </span>
        </p>}
      {HAS_OPTIN && <p className='completeProfile__checkbox__container'>
          <span>
              <input
                  className='checkbox__input'
                  type='checkbox'
                  name='marketing_opt_in'
                  id='__marketingOptIn'
                  checked={marketingOptInChecked}
                  onChange={onMarketingOptInChange}
              />
            <label className={'checkbox__label'} htmlFor={'__marketingOptIn'}/>
          </span>
        <span>
            <span
                dangerouslySetInnerHTML={{__html: I18n.t('complete_profile.marketing_optin', {terms_url: termsUrl})}}/>
          </span>
      </p>}
      <p className='completeProfile__submitWrapper'>
        <Button
          primary
          disabled={submitting}
          onClick={submit}>{I18n.t('complete_profile.submit')}</Button>
      </p>
    </div>
  )
}

export default NicknameForm
