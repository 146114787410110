import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import I18n from 'i18n-js'
import Button from './Button'

import modalSaveZone from '../misc/modal_save_zone'

const DeleteCommunityModal = ({ isOpen, onClose, communityName, communityPath, authenticityToken }) => {
  const [ saveZoneReady, setSaveZoneReady ] = useState(null)

  const formRef = useRef()

  useEffect(() => {
    modalSaveZone.onReady(() => {
      setSaveZoneReady(true)
    })
  }, [false])

  if (!saveZoneReady) return (null)

  const saveZone = modalSaveZone.calculate()

  const style = {
    // width: `${Math.min(400, saveZone.width)}px`,
    top: `${saveZone.centerY}px`,
    left: `${saveZone.centerX}px`,
    transform: `translate(-50%, -50%)`
  }

  return (
    <Modal
      isOpen={isOpen}
      appElement={document.querySelector('#neoApp')}
      parentSelector={() => document.getElementById("neoApp")}
      overlayClassName="neo__modalOverlay"
      onRequestClose={() => onClose()}
      style={{
        content: style
      }}
      className='invitedModal'>
      <div className='invitedModal__close' onClick={() => onClose()} />
      <div className='invitedModal__header'>
        <h2 className='invitedModal__title invitedModal__title--bold'>{I18n.t('community.delete_community_modal.title')}</h2>
      </div>
      <div className='invitedModal__body'>
        <p className='invitedModal__text'>
          {I18n.t('community.delete_community_modal.ask', { community_name: communityName })}
        </p>
        <div className='invitedModal__actions'>
          <form ref={formRef} className='inline-block' method='post' action={communityPath}>
            <input type='hidden' name='authenticity_token' value={authenticityToken} />
            <input type='hidden' name='_method' value='delete' />
            <Button className='invitedModal__button' primary onClick={() => formRef.current?.submit()}>
              {I18n.t('community.delete_community_modal.btn_delete_community')}
            </Button>
          </form>
          <Button onClick={() => onClose()} className='invitedModal__button' secondary>
            {I18n.t('community.delete_community_modal.btn_abort_community')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteCommunityModal
