import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import { TSquadBet, WithSecondsLeft, SquadBetPick } from "./types"
import { PICK_INDEXES_FOR_COMPLETED_PICK, SquadSlots, getSquadSlots, isValidPick } from "./helper"
import { addSecondsLeftToBet } from '@root/bet_utils/use_deadline_countdown'
import savePicks from '@root/bet_utils/save_picks'
import DisplaySaveResult, { createOkResult, createErrorResult } from '@root/bet_utils/DisplaySaveResult'
import LegacyBetContext from "@root/bet_utils/context/bet"
import _ from "lodash"
import { addPolledScoringToBet } from "./pollBetScoring"
import betStatus from '@root/bet_utils/presenter/bet_status'

const _SquadBetContext = createContext<{
  bet: WithSecondsLeft<TSquadBet>
  squadSlots: SquadSlots
  changePicks(buildNewPicks: (oldPicks: SquadBetPick[]) =>  SquadBetPick[]): void
  displaySaveResult?: any
  authToken: string
  squadViewMode: SquadViewMode
  setSquadViewMode(mode: SquadViewMode): void
}>({} as any)

const filledPicksArray = (picks: any[]) => {
  return _.times(7, (index) => picks?.[index] || null)
}

type SquadViewMode = "edit" | "view"

const checkIfEverySlotPicked = (bet: TSquadBet) => {
  return _.every(PICK_INDEXES_FOR_COMPLETED_PICK, (pickIndex) => {
    return isValidPick((bet.picks || [])[pickIndex])
  })
}

export const WithSquadBet: React.FC<PropsWithChildren<{initialBet: TSquadBet, authToken: string}>> = ({initialBet, authToken, children}) => {
  const [ picks, setPicks ] = useState(initialBet.picks || [])
  const bet = {
    ...initialBet,
    picks
  }

  addSecondsLeftToBet(bet) // seconds left automatically calculated
  addPolledScoringToBet(bet as WithSecondsLeft<TSquadBet>, authToken, 60 * 2, setPicks)
  const [ displaySaveResult, setDisplaySaveResult ] = useState<any>()

  let isEverySlotPicked = checkIfEverySlotPicked(bet)
  const [ squadViewMode, setSquadViewMode ] = useState<SquadViewMode>(
    betStatus.canEdit(bet as WithSecondsLeft<typeof bet>) && !isEverySlotPicked
    ? "edit" // initially in "edit" mode when own + open + not everything picked
    : "view" // ...else in "view" mode
  )


  const canEdit = betStatus.canEdit(bet as WithSecondsLeft<typeof bet>)
  useEffect(() => { // close edit-mode when timeout runs out (= can no longer edit)
    if (!canEdit && squadViewMode === "edit") {
      setSquadViewMode("view")
    }
  }, [canEdit])

  const changePicks = (buildNewPicks: (oldPicks: SquadBetPick[]) =>  SquadBetPick[]) => {
    const newPicks = buildNewPicks(picks.slice(0))
    setPicks(newPicks) // force component update
    savePicks(bet, authToken, filledPicksArray(newPicks),
      /* OK    */ (msg: string) => { setDisplaySaveResult(createOkResult(msg)) },
      /* ERROR */ (errorMsg: string) => { setDisplaySaveResult(createErrorResult(errorMsg)) })
  }

  return <LegacyBetContext.Provider value={bet}>
    <_SquadBetContext.Provider value={{
      changePicks,
      bet: bet as any,
      squadSlots: getSquadSlots(bet),
      displaySaveResult,
      squadViewMode,
      setSquadViewMode,
      authToken,
    }}>
      {children}
    </_SquadBetContext.Provider>
  </LegacyBetContext.Provider>
}

export const useSquadBet = () => {
  return useContext(_SquadBetContext)
}
