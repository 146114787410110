import React, { useCallback, useState } from "react"

import { useWindowSize } from "@root/misc/useWindowSize"

interface Props {
  header?: string
  content?: string
}

const InfoBadge = ({ header, content }: Props) => {
  const [left, setLeft] = useState<number | undefined>(undefined)
  const [show, setShow] = useState(false)
  const [out, setOut] = useState(false)

  const { width } = useWindowSize(100)
  
  const ref = useCallback((node: HTMLDivElement) => {
    if (node) {
      if (left) {
        return
      }
      const rect = node.getClientRects().item(0)
      if (rect) {
        if (rect.x + rect.width > width) {
          const thing = width - (rect.x + rect.width) - 50
          setLeft(thing)
        }
      }
    } else {
      setLeft(undefined)
    }
  }, [])

  const showContent = useCallback(() => {
    setShow(true)
  }, [])

  const hideContent = useCallback(() => {
    setOut(true)
    setTimeout(() => {
      setShow(false)
      setOut(false)
    }, 300)
  }, [])

  return (
    <div className="community__infoBadge" onMouseEnter={showContent} onMouseLeave={hideContent}>
      {show && (
        <div ref={ref} style={left ? { left } : {}} className={["community__infoBadge__content", out && "community__infoBadge__content--out"].join(" ")}>
          {header && <h4>{header}</h4>}
          {content && <p>{content}</p>}
          <div style={left ? { left: -left } : {}} className="community__infoBadge__content__arrow" />
        </div>
      )}
    </div>
  )
}

export default InfoBadge