import React, { useCallback } from "react"

interface Props {
  text: string
  text_to_copy: string
}

const CopyableText = ({ text, text_to_copy }: Props) => {
  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(text_to_copy)
  }, [text_to_copy])
  
  return <span className="neo__copyToClipboardText" onClick={copyToClipboard}>
    {text}
    <span className="neo__copyToClipboardText__icon" />
  </span>
}

export default CopyableText