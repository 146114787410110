import React, { useState } from 'react'
import DeleteCommunityModal from './DeleteCommunityModal'
import I18n from 'i18n-js'

import Button from "./Button"

function DeleteCommunityButton ({ community_name: communityName, community_path: communityPath, authenticity_token: authenticityToken }) {
  const [isOpen, setModalOpen] = useState(false)

  return (
    <React.Fragment>
      <DeleteCommunityModal isOpen={isOpen} onClose={() => setModalOpen(false)} communityName={communityName} communityPath={communityPath} authenticityToken={authenticityToken} />
      <a className='community__deleteButton' role='button' onClick={() => setModalOpen(true)}>
        { I18n.t('community.your_membership.btn_delete_community') }
      </a>
    </React.Fragment>
  )
}

export default DeleteCommunityButton
