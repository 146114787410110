import React, {
  KeyboardEventHandler,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState, 
} from "react"
import { SquadBetPlayer, SquadBetTeam } from "./types"
import _ from "lodash"
import I18n from "i18n-js"
import { SQUAD_PLAYER_INDEXES, unwrapSec } from "./helper"
import axios from "axios"
import Button from "../Button"
import usePicker, {
  isAdditionalConfigForSquadSlot,
  isAdditionalConfigForSubSlot,
  ScrollablePickerInnerView,
} from "./usePicker"
import { useDevice } from "@root/misc/device"
import { useSquadBet } from "./context"
import betStatus from '@root/bet_utils/presenter/bet_status'
import useOnClickOutside from "use-onclickoutside"
import { disableBodyScroll, enableBodyScroll } from '@root/misc/disableBodyScroll'
import { PlayerStats, PlayerStatsForBet, usePlayerStats, useStatsBoundToBet } from "./useStats"
import { displayPoints } from "@root/helpers/number_helpers"

const flagImgContext = require.context("@images/flags/", true, /.*/)

const SquadSectionWrapperWithTitleSection: React.FC<PropsWithChildren<{title?: string, sideContent?: React.ReactNode}>> = ({title, sideContent, children}) => {
  return <>
    <div className="squadBet__squadSectionWrapper">
      <div className="squadBet__squadSectionWrapper__head">
        {title && <h2>{title}</h2>}
        {sideContent &&
          <div className="squadBet__squadSectionWrapper__headSideContentHolder">{sideContent}</div>}
      </div>
      <div className="squadBet__squadSectionWrapper__body">{children}</div>
    </div>
  </>
}

const EditorMobilePickerHeaderSlot: React.FC<{
  player?: SquadBetPlayer
  active?: boolean
  label: string
  onClick?(): void
}> = ({label, active, player, onClick}) => {
  const className = [
    "squadEditor__mobilePickerHeaderSlot",
    active ? "squadEditor__mobilePickerHeaderSlot--active" : "squadEditor__mobilePickerHeaderSlot--notActive",
  ].join(" ")

  return <div className={className}>
    {player
      ? <div onClick={onClick} className="squadEditor__mobilePickerHeaderSlot__playerCircle"
          style={{backgroundImage: `url(${player.imageSrc})`}} />
      : <div onClick={onClick} className="squadEditor__mobilePickerHeaderSlot__emptyCircle" />}
    <div className="squadEditor__mobilePickerHeaderSlot__label">{label}</div>
  </div>
}

const EditorMobilePickerPopup: React.FC<{openPickerForSlot: OpenPickerForSlot, afterPlayerPick?(): void}> = ({openPickerForSlot, afterPlayerPick}) => {
  const closedViaClosedIconRef = useRef(false)

  const {
    viewPayload: pickerPayload,
    closePicker: __closePicker,
  } = usePicker()

  const closePicker = () => {
    closedViaClosedIconRef.current = true
    __closePicker();
  }

  const {
    bet,
    squadSlots
  } = useSquadBet()
  const betRef = useRef(bet)
  const squadSlotsRef = useRef(squadSlots)


  useEffect(() => { // block body scroll while visible
    disableBodyScroll()
    return () => { // cleanup
      enableBodyScroll()
    }
  }, [])

  const pickerFor = pickerPayload?.additionalConfig?.picksFor

  return <div className="squadEditor__mobilePickerPopup">
    <div className="squadEditor__mobilePickerPopup__head">
      <div className="squadEditor__mobilePickerPopup__closeIconHolder">
        <a onClick={closePicker} />
      </div>
      {!(pickerFor === "team") &&
        <div className="squadEditor__mobilePickerPopup__slotsHolder">
          {_.map(SQUAD_PLAYER_INDEXES, (sIndex) => {
            return <EditorMobilePickerHeaderSlot
              key={sIndex}
              label={I18n.t(`squadbet.slot${sIndex}_group_title`)}
              onClick={openPickerForSlot.slot[sIndex]}
              player={squadSlots.squadSlots[sIndex]?.player}
              active={isAdditionalConfigForSquadSlot(pickerFor) && pickerFor.slot === sIndex} />
          })}
          <EditorMobilePickerHeaderSlot
              key="sub"
              onClick={openPickerForSlot.sub}
              player={squadSlots.substitutePlayer || undefined}
              label={I18n.t("squadbet.sub_group_title--short")}
              active={isAdditionalConfigForSubSlot(pickerFor)} />
        </div>}
    </div>
    <div className="squadEditor__mobilePickerPopup__body">
      <div className="squadEditor__mobilePickerPopup__pickerHolder">
        <ScrollablePickerInnerView numCols={1} afterPlayerPick={afterPlayerPick} />
      </div>
    </div>
  </div>
}

interface SquadSectionProps {
  title?: string
  titleSideContent?: React.ReactNode
  squadItems: ReactNode[]
  teamItem: ReactNode
  subItem?: ReactNode
}

const SquadEditorIntroTooltipIcon: React.FC = () => {
  const [ showIntroTooltip, setShowIntroTooltip ] = useState(false)
  const showRef = useRef(showIntroTooltip)
  showRef.current = showIntroTooltip
  const ref = useRef<HTMLElement>()

  useOnClickOutside(ref as any, () => {
    window.setTimeout(() => {
      if (showRef.current) { setShowIntroTooltip(false) }
    }, 1);
  })

  return <div ref={ref as any} className="squadEditor__introTooltip__holder">
    {showIntroTooltip &&
      <div onClick={() => setShowIntroTooltip(false)} className="squadEditor__introTooltip__tooltip" dangerouslySetInnerHTML={{__html: I18n.t("squadbet.intro_explanation--html")}} />}
    <div className="squadEditor__introTooltip__icon"><a onClick={() => setShowIntroTooltip(val => !val)} /></div>
  </div>
}


interface OpenPickerForSlot {
  slot: (() => void)[]
  sub: () => void
  team: () => void
}

export const SquadEditor: React.FC<SquadSectionProps & {openPickerForSlot: OpenPickerForSlot}> = ({title, openPickerForSlot, titleSideContent, squadItems, subItem, teamItem}) => {
  const { viewIsVisible: pickerOpened, viewPayload: pickerPayload, closePicker } = usePicker()
  const { isTabletUp, isDesktopUp } = useDevice()
  const { bet, squadSlots, setSquadViewMode } = useSquadBet()
  const openPickerForSlotRef = useRef(openPickerForSlot)
  openPickerForSlotRef.current = openPickerForSlot

  // to get up-to-date vals after pick
  const squadSlotsRef = useRef(squadSlots)
  squadSlotsRef.current = squadSlots
  const betRef = useRef(bet)
  betRef.current = bet

  const teamSectionRef = useRef<HTMLElement>()
  const [teamSectionHeight, setTeamSectionHeight] = useState<number>()
  const teamSectionHeightRef = useRef(teamSectionHeight)
  teamSectionHeightRef.current = teamSectionHeight

  useEffect(() => {
    const onTick = () => {
      if (teamSectionRef.current) {
        const newHeight = teamSectionRef.current.getBoundingClientRect().height
        if (teamSectionHeightRef.current !== newHeight) {
          setTeamSectionHeight(newHeight)
        }
      }
    }
    const interval = window.setInterval(onTick, 700)
    onTick()

    return () => { // cleanup
      window.clearInterval(interval)
    }
  }, [])

  const pickerPos: "asOverlay" | "inContent" = isTabletUp
    ? "inContent"
    : "asOverlay"

  const afterPlayerPickReopenPickerForNextEmptySlot = () => {
    if (!betStatus.canEdit(betRef.current)) { return } // most likely unmounted because bet now closed

    let foundEmptySlot = false

    _.each(squadSlotsRef.current.squadSlots, (s, index) => {
      if (!foundEmptySlot && !s) {
        openPickerForSlotRef.current.slot[index]() // reopen after closing with next slot
        foundEmptySlot = true
      }
    })
    if (!foundEmptySlot && !squadSlotsRef.current.substitutePlayer) {
      openPickerForSlotRef.current.sub() // reopen after closing with substitute player
      foundEmptySlot = true
    }  
  }

  return <SquadSectionWrapperWithTitleSection title={title} sideContent={titleSideContent}>
    <div className="squadEditor">
      <div ref={teamSectionRef as any} className="squadEditor__teamSection">
        <div className="squadEditor__teamSection__title">
          <h3>{I18n.t("squadbet.your_players_title")}</h3>
          <SquadEditorIntroTooltipIcon />
        </div>
        <div className="squadEditor__items">
          {squadItems}
        </div>
        <div className="squadEditor__teamSection__divider" />
        <div className="squadEditor__items">
          {subItem}
          {teamItem}
        </div>
      </div>
      {pickerPos === "asOverlay" && pickerOpened && <EditorMobilePickerPopup
        openPickerForSlot={openPickerForSlot}
        afterPlayerPick={afterPlayerPickReopenPickerForNextEmptySlot} />}
      {pickerPos === "inContent" &&
        <div className="squadEditor__pickerSection" style={_.isNumber(teamSectionHeight) ? {height: `${teamSectionHeight}px`} : undefined}>
          {pickerOpened
            ? <>{/* PICKER OPEN (DESKTOP) */}
                {pickerPayload?.additionalConfig?.title &&
                  <h4>{pickerPayload.additionalConfig.title}
                    <a className="squadEditor__pickerSection__closePicker" onClick={closePicker} />
                  </h4>}
                <div className="squadEditor__pickerOpenPositioner">
                  <div className="squadEditor__pickerOpenHolder">
                    <ScrollablePickerInnerView numCols={isDesktopUp ? 2 : 1} />
                  </div>
                </div>
              </>
            : <>{/* PICKER CLOSED */}
                <div className="squadEditor__infoWhenPickerClosed">
                  <p>
                    {squadSlots.areSlotsBenchTeamCompletelyPicked
                      ? I18n.t("squadbet.edit_mode.info_every_slot_picked")
                      : I18n.t("squadbet.edit_mode.info_please_pick")
                    }
                  </p>
                  {squadSlots.areSlotsBenchTeamCompletelyPicked &&
                    <>
                      <p>
                        <Button primary title={I18n.t("squadbet.edit_mode.action_to_view_mode")} onClick={() => setSquadViewMode("view")} />
                      </p>
                      <p className="squadEditor__infoWhenPickerClosed__infoCanChangeLater">
                        {I18n.t("squadbet.edit_mode.info_can_edit_later_as_long_as_open")}
                      </p>
                    </>}
                </div>
              </>}
        </div>}
    </div>
  </SquadSectionWrapperWithTitleSection>
}

export const SquadSection: React.FC<SquadSectionProps> = ({title, titleSideContent, squadItems, subItem, teamItem}) => {
  return <SquadSectionWrapperWithTitleSection title={title} sideContent={titleSideContent}>
    <div className="squadBet__squadSection">
      <div className="squadBet__squadSection__squadHolder">
        {squadItems}
      </div>
      <div className="squadBet__squadSection__divider" />
      <div className="squadBet__squadSection__subAndTeamHolder">
        {subItem}
        {teamItem}
      </div>
    </div>
  </SquadSectionWrapperWithTitleSection>
}

export const SquadPlayerItemsHolder: React.FC<{items: ReactNode[]}> = ({items}) => {
  return <div className="squadBet__playerItemsHolder">
    {_.map(items, (i, index) => {
      return <div key={index} className="squadBet__playerItemsHolder__item">{i}</div>
    })}
  </div>
}

export const PlayerItemInner: React.FC<{player: SquadBetPlayer}> = ({player}) => {
  return <div className="squadBet__playerPickItem">
    <div className={`squadBet__playerPickItem__teamBg livTeam--${player.team_key}`} />
    <img className="squadBet__playerPickItem__playerImg" src={player.imageSrc} />
    {player.countryCode && <img className="squadBet__playerPickItem__flagImg" src={flagImgContext(`./${player.countryCode.toLocaleUpperCase()}.svg`)} />}
  </div>
}

export const TeamItemInner: React.FC<{team: SquadBetTeam}> = ({team}) => {
  return <div className={`squadBet__teamPickItem livTeam--${team.team_key}`} />
}

type ItemPlaceholderType = "player" | "team"

export const PlusItemInner: React.FC<{placeholderType?: ItemPlaceholderType}> = ({placeholderType}) => {
  placeholderType || (placeholderType = "player")
  return <div className={`squadBet__plusItemInner squadBet__plusItemInner--${placeholderType}`}><span>{I18n.t("squadbet.action_pick")}</span></div>
}

export const PlayerDummyItemInner: React.FC<{placeholderType?: ItemPlaceholderType}> = ({placeholderType}) => {
  placeholderType || (placeholderType = "player")
  return <div className={`squadBet__playerDummyPickItem squadBet__playerDummyPickItem--${placeholderType}`} />
}

export const SquadBetCountdown: React.FC<{secondsLeft: number}> = ({secondsLeft}) => {
  const { d, hh, mm } = unwrapSec(secondsLeft)
  return secondsLeft > 0
    ? <div className="squadBet__countdown">
        <div className="squadBet__countdown__label">{I18n.t("squadbet.countdown.countdown")}</div>
        <div className="squadBet__countdown__values">
          <div>
            <strong>{d}</strong>
            <span>{I18n.t("squadbet.countdown.days")}</span>
          </div>
          <div>
            <strong>{hh}</strong>
            <span>{I18n.t("squadbet.countdown.hours")}</span>
          </div>
          <div>
            <strong>{mm}</strong>
            <span>{I18n.t("squadbet.countdown.minutes")}</span>
          </div>
        </div>
      </div>
    : <div className="squadBet__countdown__alreadyStarted">
        <span  className="squadBet__countdown__alreadyStarted__note">{I18n.t("squadbet.countdown.event_started")}</span>
      </div>
}
export const EmptyItemInner: React.FC<{placeholderType?: ItemPlaceholderType, emptyReason: "nothingPicked" | "censored"}> = ({emptyReason, placeholderType}) => {
  placeholderType || (placeholderType = "player")

  return <div className={`squadBet__emptyItemInner squadBet__emptyItemInner--${placeholderType}`}>
    <span>{emptyReason === "nothingPicked" ? "-" : "?"}</span>
  </div>
}

export interface NewPickItemProps {
  itemFor: ItemPlaceholderType
  player?: SquadBetPlayer
  team?: SquadBetTeam
  linkStats?: boolean
  warning?: string // "only 2 players per team allowed!"
  markSelectedForPicker?: boolean
  markGreyedOut?: boolean // for greyout, but it just marks! it doesn't cancel onPick
  onPick?(): void
  showInlineStats?: boolean
  score?: number
  icon?: React.ReactNode
  lineAboveTitle?: string
  titleIfNoPlayerOrTeam?: string
  verticalSize: "narrow" | "large"
  buttonInImageContainer?: {
    text: string,
    iconUrl?: string,
    onClick(): void
  }
}

const LINK_STATS_FEATURE_ENABLED = true

export const NewPickItem: React.FC<NewPickItemProps> = ({
  itemFor,
  player,
  team,
  linkStats,
  buttonInImageContainer,
  warning,
  markSelectedForPicker,
  markGreyedOut,
  onPick,
  showInlineStats,
  score,
  icon,
  lineAboveTitle,
  titleIfNoPlayerOrTeam,
  verticalSize,
}) => {
  const [statsUnfolded, setStatsUnfolded] = useState(false)

  const className = [
    "squadBet__newPick",
    verticalSize === "large" ? "squadBet__newPick--large" : "squadBet__newPick--narrow",
    onPick ? "squadBet--actionable" : "squadBet--notActionable",
    markSelectedForPicker ? "squadBet--markSelectedForPicker" : "",
    markGreyedOut ? "squadBet--markGreyedOut" : ""
  ].join(" ")

  const showStatsLink = LINK_STATS_FEATURE_ENABLED && linkStats && player && !statsUnfolded
  const showUnfoldedStats = LINK_STATS_FEATURE_ENABLED && linkStats && player && statsUnfolded
  const makePlaceForStatsLink = showStatsLink || showUnfoldedStats

  return <div className={className}>
    <div className="squadBet__newPick__normalContentHolder">
      <div className="squadBet__newPick__imageSection" onClick={onPick}>
        {player
          ? <NewPickPlayer player={player} />
          : (team
            ? <NewPickTeam team={team} />
            : (itemFor === "player" ? <NewPickPlayerPlaceholer /> : <NewPickTeamPlaceholder />))}
        {buttonInImageContainer &&
          <span className="squadBet__newPick__buttonInImageContainer__holder">
            <a className="squadBet__newPick__buttonInImageContainer" onClick={buttonInImageContainer.onClick}>
              {buttonInImageContainer.iconUrl &&
                <span className="squadBet__newPick__buttonInImageContainer__icon" style={{backgroundImage: `url(${buttonInImageContainer.iconUrl})`}} />}
              <span className="squadBet__newPick__buttonInImageContainer__text">{buttonInImageContainer.text}</span>
            </a>
          </span>}
      </div>
      <div className={`squadBet__newPick__textSection ${makePlaceForStatsLink ? "squadBet__newPick__textSection--withPlaceForStatsLink" : ""}`}>
        {warning && <div className="squadBet__newPick__textSection__warning">{warning}</div>}
        {lineAboveTitle && <div className="squadBet__newPick__textSection__lineAboveTitle">{lineAboveTitle}</div>}
        <h4 className="squadBet__newPick__textSection__title">
          {player
            ? player.name
            : (team
              ? team.name
              : titleIfNoPlayerOrTeam || "")}
        </h4>
        {player?.captain && <div className="squadBet__newPick__captainMarker"><span>{I18n.t("squadbet.label_captain")}</span></div>}
        {showInlineStats && player?.stats && <div className="squadBet__newPick__statsSection">
          {/* <div>
            <span>{I18n.t("squadbet.stat_player_rank_label")}</span>
            <strong>{I18n.t("squadbet.stat_player_rank", {rank: player.stats.rank, total: player.stats.total_ranks})}</strong>
          </div> */}
          <div>
            <span>{I18n.t("squadbet.stat_picked_by_label")}</span>
            <strong>{`${player.stats.perc_picked}%`}</strong>
          </div>
        </div>}
        {showStatsLink &&
          <div className="squadBet__newPick__showStatsLinkHolder">
            <a onClick={() => setStatsUnfolded(true)}>{I18n.t(player ? "squadbet.action_show_player_stats" : "squadbet.action_show_team_stats")}</a>
          </div>}
      </div>
      {_.isNumber(score)
        ? <div className="squadBet__newPick__iconOrScoreSection">
            <div className={`squadBet__newPick__score squadBet__newPick__score--${score === 0 ? "zero" : "notZero"}`}>
              {scoreValue(score, true)}
            </div>
          </div>
        : (icon && <div className="squadBet__newPick__iconOrScoreSection" onClick={icon ? onPick : undefined}>
            {icon}
          </div>)}
    </div>
    {showUnfoldedStats &&
      <div className="squadBet__newPick__inlineStatsHolder">
        {player && <InlinePlayerStatsView key={player.id} player={player} />}
        <div className="squadBet__newPick__hideStatsLinkHolder">
          <a onClick={() => setStatsUnfolded(false)}>{I18n.t("squadbet.action_hide_stats")}</a>
        </div>
      </div>}
  </div>
}

const InlinePlayerStatsView: React.FC<{player: SquadBetPlayer}> = ({player}) => {
  const { bet } = useSquadBet()
  const [ playerStats, error, loading ] = usePlayerStats(player.id, bet.bet_id)

  const RowOrLoading: React.FC<{valKey: keyof PlayerStats}> = ({valKey}) => {
    return <div className="squadBet__inlineStats__globalAndRoundStats__row">
      <span>{I18n.t(`squadbet.stats.${valKey}.label`)}</span>
      <strong>{I18n.t(`squadbet.stats.${valKey}.value`, {value: playerStats?.global?.[valKey] ?? 0})}</strong>
      <strong>{I18n.t(`squadbet.stats.${valKey}.value`, {value: playerStats?.round?.[valKey] ?? 0})}</strong>
    </div>
  }

  return <div>
    {loading
      ? <div className="squadBet__inlineStats__loadingPlaceholder" />
      : (!error && playerStats && <>
          <div className="squadBet__inlineStats">            
            <div className="squadBet__inlineStats__globalAndRoundStats">
              <div className="squadBet__inlineStats__globalAndRoundStats__row">
                <span></span>
                <strong>{I18n.t("squadbet.stats.tabs.global")}</strong>
                <strong>{I18n.t("squadbet.stats.tabs.round")}</strong>
              </div>
              <RowOrLoading valKey="avg_driving_distance" />
              <RowOrLoading valKey="avg_putts_per_hole" />
              <RowOrLoading valKey="avg_score" />
              <RowOrLoading valKey="fairway_hit_percent" />
              <RowOrLoading valKey="greens_in_regulation_percent" />
              <RowOrLoading valKey="scrambling_percent" />
            </div>
          </div>
          {(playerStats.score_history || []).length > 0 && <div className="squadBet__inlineStats__history">
            <h4>{I18n.t("squadbet.stats.score_history.title")}</h4>
            <div className="squadBet__inlineStats__history__list">
              {_.map(playerStats?.score_history || [], (item, index) => {
                return <div key={index}>
                  <span>{item.round_name.substring(0, 3)}</span>
                  <strong>{item.score === 0 ? "-" : displayPoints(item.score)}</strong>
                </div>
              })}
            </div>
          </div>}
        </>)}
  </div>
}

export const scoreValue = (score?: number, replace0withE?: boolean) => {
  return _.isNumber(score)
    ? (score === 0
        ? replace0withE ? "E" : score
        : (score < 0
          ? `-${-score}`
          : `+${score}`))
    : "-"
}

export const NewPickIcon: React.FC<{type: "pickArrow" | "check" | "plus" | "edit"}> = ({type}) => {
  return <div className={`squadBet__newPickIcon squadBet__newPickIcon--${type}`} />
}

const NewPickPlayer: React.FC<{player: SquadBetPlayer}> = ({player}) => {
  return <div className="squadBet__newPickPlayer">
    <div className="squadBet__newPickPlayer__playerImg" style={{backgroundImage: `url(${player.imageSrc})`}} />
    <div className={`squadBet__newPickPlayer__playerTeam livTeam--${player.team_key}`} />
    {player.countryCode && <div className="squadBet__newPickPlayer__flagImg" style={{backgroundImage: `url(${flagImgContext(`./${player.countryCode.toLocaleUpperCase()}.svg`)}`}} />}
  </div>
}

const NewPickPlayerPlaceholer: React.FC = () => {
  return <div className="squadBet__newPickPlayerPlaceholder" />
}

const NewPickTeam: React.FC<{team: SquadBetTeam}> = ({team}) => {
  return <div className={`squadBet__newPickTeam livTeam--${team.team_key}`} />
}

const NewPickTeamPlaceholder: React.FC = () => {
  return <div className="squadBet__newPickTeamPlaceholder" />
}

export interface PickItemHolderProps {
  title?: string
  placeholderTitle?: string
  score?: number
  greyout?: Boolean
  aboveTitle?: string
  markSelectedForPicker?: boolean
  above?: ReactNode
  below?: ReactNode
  stats?: (string[])[]
  actionButton?: {text: string, action(): void}
}
export const PickItemHolder: React.FC<PropsWithChildren<PickItemHolderProps>> = ({above, aboveTitle, actionButton, children, greyout, placeholderTitle, stats, markSelectedForPicker, title, below}) => {
  const className = [
    "squadBet__pickItemHolder",
    greyout ? "squadBet__pickItemHolder--greyout" : "",
    markSelectedForPicker ? "squadBet__pickItemHolder--markSelectedForPicker" : "",
  ].join(" ")
  return <div className={className}>
    {above && <div className="squadBet__pickItemHolder__above">{above}</div>}
    <div className="squadBet__pickItemHolder__pick">{children}</div>
    <div className="squadBet__pickItemHolder__textStuff">
      {aboveTitle && <div className="squadBet__pickItemHolder__aboveTitle">{aboveTitle}</div>}
      {placeholderTitle && <div className="squadBet__pickItemHolder__placeholderTitle">{placeholderTitle}</div>}
      {title && <div className="squadBet__pickItemHolder__title">{title}</div>}
      {stats && <div className="squadBet__pickItemHolder__stats">
        {_.map(stats, (s, index) => {
          return <div key={index}><span>{s[0]}</span><strong>{s[1]}</strong></div>
        })}</div>}
      {actionButton && <div className="squadBet__pickItemHolder__actionButton">
        <a onClick={() => actionButton.action()}>{actionButton.text}</a></div>}
    </div>
    {below && <div className="squadBet__pickItemHolder__below">{below}</div>}
  </div>
}

interface PickItemProps {
  actionable: Boolean
  onClick?(): void
  withChangeIcon?: Boolean
}
export const PickItem: React.FC<PropsWithChildren<PickItemProps>> = ({actionable, onClick, withChangeIcon, children}) => {
  const Inner = <>
      <span className="squadBet__pickItem__inner">{children}</span>
      {withChangeIcon && <span className="squadBet__pickItem__changeIcon" />}
    </>

  return actionable
    ? <a className="squadBet__pickItem" onClick={() => onClick?.()}>{Inner}</a>
    : <span className="squadBet__pickItem">{Inner}</span>
}


// ---- TIEBREAKER QUESTION ----- //

const postAnswerIfValid = (roundId: number, answerString: string, authenticity_token: string) => {
  return new Promise((resolve, reject) => {
    if (/^-?[0-9]+$/.test(answerString)) {
      axios.post("/tiebreaker/", {
        authenticity_token,
        round_id: roundId,
        answer_value: answerString
      }).then(resolve).catch(reject)
    } else {
      reject(I18n.t("squadbet.tiebreaker_question.no_valid_number"))
    }
  })
}

interface InputProps {
  inputRef: React.RefObject<HTMLInputElement>
  answerValue: string
  setAnswerValue: React.Dispatch<React.SetStateAction<string>>
  sendAnswer: () => void
}

const TiebreakerInput: React.FC<InputProps> = ({ inputRef, answerValue, setAnswerValue, sendAnswer }) => {

  const onKeyDown: KeyboardEventHandler = useCallback((e) => {
    if (e.key === "Enter") {
      sendAnswer()
    }
  }, [sendAnswer])

  const increaseAnswer = useCallback(() => {
    setAnswerValue(
      (current) => {
        const currentAnswer = parseInt(current)

        if (_.isNaN(currentAnswer)) {
          return "1"
        }

        return (currentAnswer + 1).toString()
      }
    )
  }, [])

  const decreaseAnswer = useCallback(() => {
    setAnswerValue(
      (current) => {
        const currentAnswer = parseInt(current)

        if (_.isNaN(currentAnswer)) {
          return "-1"
        }

        return (currentAnswer - 1).toString()
      }
    )
  }, [])

  return (
    <div className="tiebreakerQuestion__inputWrapper">
      <div className="tiebreakerQuestion__numberInputHolder">
        <input
          type="number"
          ref={inputRef}
          className="input input--higher"
          onKeyDown={onKeyDown}
          value={answerValue}
          onChange={(e) => setAnswerValue(e.target.value)}
          placeholder={I18n.t("squadbet.tiebreaker_question.answer_placeholder")}
          />
          <div className="numberInput__spinnerButtons">
            <div
              className="numberInput__spinnerButton numberInput__spinnerButton--up"
              onClick={increaseAnswer}
            />
            <div
              className="numberInput__spinnerButton numberInput__spinnerButton--down"
              onClick={decreaseAnswer}
            />
          </div>
      </div>
      <Button primary noMinWidthOnMobile onClick={sendAnswer} title={I18n.t("squadbet.tiebreaker_question.btn_save")} />
    </div>
  )
}

type FeedbackMessage = ErrorMessage | SuccessMessage | NullMessage
type NullMessage = { type: "null", message: ""}
type ErrorMessage = { type: "error", message: string }
type SuccessMessage = { type: "success", message: string }

const NullMessage = (): NullMessage => ({ type: "null", message: "" })

const FeedbackMessageComponent: React.FC<{feedbackMsg: FeedbackMessage}> = ({feedbackMsg}) => {

  const classes = useMemo(() => [
    "tiebreakerQuestion__feedbackMessage",
    feedbackMsg ? `tiebreakerQuestion__feedbackMessage--${feedbackMsg.type}` : "",
  ].join(" "), [feedbackMsg])

  return (
    <div className={classes}>
      {feedbackMsg.message}&nbsp;
    </div>
  )
}

interface TiebreakerProps {
  question: string
  answer?: string | number
  round: number
  authToken: string
  canEdit: boolean
}

const DEFAULT_PICKER_VALUE = ""

export const TiebreakerQuestion: React.FC<TiebreakerProps> = ({ round, question, answer, authToken, canEdit }) => {
  const [feedbackMsg, setFeedbackMsg] = useState<FeedbackMessage>(NullMessage())
  const [answerValue, setAnswerValue] = useState<string>(answer?.toString() || DEFAULT_PICKER_VALUE);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [timer])

  const sendAnswer = useCallback(() => {
    postAnswerIfValid(round, answerValue, authToken)
      .then(() => {
        setFeedbackMsg({ type: "success", message: I18n.t("squadbet.tiebreaker_question.success")})
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(() => {
          setFeedbackMsg(NullMessage())
        }, 5000))
      })
      .catch((err) => {
        inputRef.current?.focus()
        inputRef.current?.select()
        if (err.response && err.response.data && err.response.data.message) {
          setFeedbackMsg({type: "error", message: err.response.data.message})
        } else {
          setFeedbackMsg({type: "error", message: err.toString()})
        }
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(() => {
          setFeedbackMsg(NullMessage())
        }, 5000))
      })
  }, [answerValue, inputRef.current])

  return (
    <div className="tiebreakerQuestion__wrapper">
      <div className="tiebreakerQuestion__header">
        <div className="tiebreakerQuestion__headerMeta">
          <h3>{I18n.t("squadbet.tiebreaker.title")}</h3>
          <span>{question}</span>
        </div>
      </div>
      <div className="tiebreakerQuestion__body">
        {canEdit
          ? <>
              <TiebreakerInput
                inputRef={inputRef}
                answerValue={answerValue}
                setAnswerValue={setAnswerValue}
                sendAnswer={sendAnswer}
              />
              <FeedbackMessageComponent feedbackMsg={feedbackMsg} />
            </>
          : <div className="tiebreakerQuestion__resultWrapper">
              {answer
                ? <>
                    <div className="tiebreakerQuestion__resultLabel">{I18n.t("squadbet.tiebreaker_question.result.label")}</div>
                    <div className="tiebreakerQuestion__result">{answer}</div>
                  </>
                : <div className="tiebreakerQuestion__resultLabel">{I18n.t("squadbet.tiebreaker_question.result.not_given")}</div>
              }
            </div>
        }
      </div>
    </div>
  )
}
