import I18n from "i18n-js"
import React, { useMemo } from "react"

interface Option {
  id: number
  name: string
  meta: { image: string }
}

interface Slot {
  name: string
  options: Option[]
}

interface TopRank {
  nickname: string
  profile_id: number
  rank: number
  points: number
  country_code: string
  slots?: Slot[]
}

const Slot = ({ options }: Slot) => {
  return (
    <div className="top3Ranks__item__picksSlot">
      <img src={options[0].meta.image} />
    </div>
  )
}

const EmptySlot = () => {
  return <div className="top3Ranks__item__picksEmptySlot" />
}

const Rank = ({ nickname, rank, points, country_code, slots: rankSlots }: TopRank) => {
  const { slots, team, teamName } = useMemo(() => {

    const slots = Array.from({ length: 4 }, (_, index) => {
      return rankSlots?.find((slot) => slot.name === `tier-${index + 1}`) || null
    })

    const team = rankSlots?.find((slots) => slots.name === "teams")
    const teamName = team?.options[0].name.toLocaleLowerCase().replace(" ", "")

    return {
      slots,
      team,
      teamName,
    }
  }, [rankSlots])
  
  return <div className="top3Ranks__item">
    <div className="top3Ranks__item__topContainer">
      <div className="top3Ranks__item__rank">{rank}</div>
      <div className="top3Ranks__item__info">
        <div className="top3Ranks__item__infoName">{nickname}</div>
        <div className="top3Ranks__item__infoCountry">
          <div className={`roundFlag--${country_code}`} />
          <div>{country_code}</div>
        </div>
      </div>
    </div>
    <div className="top3Ranks__item__divider" />
    <div className="top3Ranks__item__bottomContainer">
      <div className="top3Ranks__item__picksContainer">
        <div className="top3Ranks__item__picksTitle">{I18n.t("ranking.top_3.team")}</div>
        <div className="top3Ranks__item__picks">
          {slots.map((slot) => {
            if (slot) {
              return <Slot {...slot} />
            }
            return <EmptySlot />
          })}
        </div>
      </div>
      <div className="top3Ranks__item__teamAndScore">
        <div className="top3Ranks__item__teamPick">
          {team ? (
            <div className="top3Ranks__item__teamPickInfo">
              <div className={`top3Ranks__item__teamPickSlot livTeam__col--${teamName} livTeam__img--${teamName}`} />
              <div className="top3Ranks__item__teamPickName">{team.options[0].name}</div>
            </div>
          ) : (
            <div className="top3Ranks__item__teamPickInfo">
              <div className="top3Ranks__item__emptyTeamPickSlot" />
              <div className="top3Ranks__item__teamPickName">-</div>
            </div>
          )}
        </div>
        <div className="top3Ranks__item__scoreContainer">
          <div className="top3Ranks__item__scoreTitle">{I18n.t("ranking.top_3.score")}</div>
          <div className="top3Ranks__item__score">{points}</div>
        </div>
      </div>
    </div>
  </div>
}

interface Props {
  top_3: TopRank[]
}

const Top3Ranks = ({ top_3: top3 }: Props) => {
  return <div className="top3Ranks__container">
    {top3.map((rank) => {
      return <Rank {...rank} />
    })}
  </div>
}

export default Top3Ranks