import React, { useState } from "react"
import { WithSquadBet, useSquadBet } from "./context"
import DisplaySaveResult from "@root/bet_utils/DisplaySaveResult"
import _ from "lodash"
import I18n from "i18n-js"
import betStatus from '@root/bet_utils/presenter/bet_status'
import CancelledInfo from '@root/bet_utils/CancelledInfo'
import PostponedInfo from '@root/bet_utils/PostponedInfo'
import {
  BENCH_INDEX,
  SUB_PLAYER_INDEX,
  SquadBetPlayerSlot,
  SquadBetTeamSlot,
  TEAM_INDEX,
  sortPlayers,
  getPlayersFromSlot,
  unwrapSec
} from "./helper"
import { useDevice } from "@root/misc/device"
import BetStatus from "@root/bet_utils/BetStatus"
import usePicker, { isAdditionalConfigForSquadSlot, isAdditionalConfigForSubSlot, isAdditionalConfigForTeamSlot, PickerEntry, WithPicker } from "./usePicker"
import { BaseBet, SquadBetPlayer, SquadBetTeam, TSquadBet, WithSecondsLeft } from "./types"
import { WithStats, useStatsBoundToBet } from "./useStats"
import swapImgUrl from "@images/iconSquadBet__sub.png"
import {
  EmptyItemInner,
  NewPickIcon,
  NewPickItem,
  NewPickItemProps,
  PickItem,
  PickItemHolder,
  PickItemHolderProps,
  PlayerDummyItemInner,
  PlayerItemInner,
  PlusItemInner,
  scoreValue,
  SquadBetCountdown,
  SquadEditor,
  SquadPlayerItemsHolder,
  SquadSection,
  TeamItemInner,
  TiebreakerQuestion,
} from "./ui"
import { addSecondsLeftToBet } from "@root/bet_utils/use_deadline_countdown"
import { countdownSegmentsToComp, CountdownText } from "../RoundSummaries"
import Button from "../Button"

const locationImgContext = require.context("@images/", true, /location--.*/)

export const DayHeader: React.FC<{hideViewUnfoldedToggler?: boolean, viewIsUnfolded: boolean, toggleViewIsUnfolded(): void}> = ({viewIsUnfolded, toggleViewIsUnfolded, hideViewUnfoldedToggler}) => {
  const {
    bet
  } = useSquadBet()
  return <div className="squadBet__dayHeader">
    {!hideViewUnfoldedToggler &&
      <div className="squadBet__dayHeader__viewUnfoldToggleHolder">
        <a
          onClick={toggleViewIsUnfolded}
          className={[
            "squadBet__dayHeader__viewUnfoldedToggle",
            viewIsUnfolded ? "squadBet__dayHeader__viewUnfoldedToggle--isUnfolded" : "squadBet__dayHeader__viewUnfoldedToggle--isNotUnfolded",
          ].join(" ")} />
      </div>}
    <div className="squadBet__dayHeader__titleHolder">
      <h3>
        <span className="squadBet__dayHeader__date">{betStatus.prettyDateOrCancelledOrTBD(bet, "MMM D, HH:mm")}</span>
        <span className="squadBet__dayHeader__title">{I18n.t("squadbet.day_header", {day_num: bet.day_index + 1})}</span>
      </h3>
    </div>
    <div className="squadBet__dayHeader__statusHolder">
      <BetStatus
        cssNamespace="squadBet__betStatus"
        overAndScored={(score: number) => <><span>{I18n.t("squadbet.day_score")}</span><strong><ScoreValue score={score} /></strong></>}/>
    </div>
  </div>
}

const MatchdayHeader: React.FC = () => {
  const { isPhone } = useDevice()
  const {
    bet
  } = useSquadBet()

  const Title = <h2 className="squadBet__matchdayHeader__title">{bet.event_name}</h2>
  const EventDate = <span className="squadBet__matchdayHeader__eventDate">{bet.event_subtitle}</span>
  const CountdownArea = <div className="squadBet__matchdayHeader__countdownHolder"><SquadBetCountdown secondsLeft={betStatus.isMatchOpen(bet) ? bet.secondsLeft : 0} /></div>
  const locationImgSrc = locationImgContext(`./location--${bet.location.toLocaleLowerCase()}.jpg`)

  return <div className="squadBet__matchdayHeader__wrapper">
    {isPhone
      ? <div className="squadBet__matchdayHeader__phone">
          <div className="squadBet__matchdayHeader__phone__titleHolder">{Title}</div>
          <div className="squadBet__matchdayHeader__phone__locationImg" style={{backgroundImage: `url(${locationImgSrc})`}} />
          <div className="squadBet__matchdayHeader__phone__dateAndCountdownHolder">
            {EventDate}
            {CountdownArea}
          </div>
        </div>
      : <div className="squadBet__matchdayHeader__desktop">
          <div className="squadBet__matchdayHeader__desktop__titleAndCountdownHolder">
            {Title}
            {EventDate}
            {CountdownArea}
          </div>
          <div className="squadBet__matchdayHeader__desktop__locationImg" style={{backgroundImage: `url(${locationImgSrc})`}} />
        </div>}
    </div>
}

const defaultPropsForPlayerItemHolder = (s: SquadBetPlayerSlot): Partial<PickItemHolderProps> => {
  return {
    aboveTitle: s.player.captain ? I18n.t("squadbet.label_captain") : undefined,
    title: s.player.name,
    stats: s.player.stats ? [
      [I18n.t("squadbet.stat_player_rank_label"), I18n.t("squadbet.stat_player_rank", {rank: s.player.stats.rank, total: s.player.stats.total_ranks})],
      [I18n.t("squadbet.stat_picked_by_label"), `${s.player.stats.perc_picked}%`],
    ] : undefined
  }
}

const defaultPropsForTeamItemHolder = (t: SquadBetTeamSlot): Partial<PickItemHolderProps> => {
  return {
    title: t.team.name,
    stats: t.team.stats ? [
      [I18n.t("squadbet.stat_team_rank_label"), I18n.t("squadbet.stat_team_rank", {rank: t.team.stats.rank ?? "0", total: t.team.stats.total_ranks})],
      [I18n.t("squadbet.stat_picked_by_label"), `${t.team.stats.perc_picked}%`],
    ] : undefined
  }
}

export const ScoreValue: React.FC<{score?: number, replace0withE?: boolean}> = ({score, replace0withE}) => {
  return <span className="squadBet__scoreValue">
    {scoreValue(score, replace0withE)}
  </span>
}

const ScoreBelowPlayer: React.FC<{score: number, replace0withE?: boolean}> = ({score, replace0withE}) => {
  return <strong className="squadBet__scoringDetails__score"><ScoreValue score={score} replace0withE={replace0withE} /></strong>
}

const SelectSquad: React.FC = () => {
  const {
    bet,
    squadSlots,
    changePicks,
    setSquadViewMode,
  } = useSquadBet()
  const {
    pickPlayer: pickerPickPlayer,
    pickTeam: pickerPickTeam,
    viewPayload: pickerPayload,
  } = usePicker()

  const pickPlayer = (slotIndex: number) => {
    const playerInSlot = squadSlots.squadSlots[slotIndex]?.player
    const numPickedCaptains = squadSlots.amountPickedCaptains(playerInSlot)

    const playersForSlot = getPlayersFromSlot(bet, slotIndex)

    pickerPickPlayer(_.map(playersForSlot.sort(sortPlayers), (p) => {
      let cantPickReason: string = ""
      let canPick: Boolean = true
      const alreadyInSquad = _.some(squadSlots.allPickedPlayers(), (pl) => pl.id === p.id)
      if (!alreadyInSquad) {
        if (p.captain && numPickedCaptains >= 1) {
          canPick = false
          cantPickReason = I18n.t("squadbet.cant_pick_because_captain")
        } else if (squadSlots.amountPickedPlayersPerTeam(p.team_key) >= 2) {
          canPick = false
          cantPickReason = I18n.t("squadbet.cant_pick_because_team")
        }
      }
      const entry: PickerEntry<SquadBetPlayer> = {
        item: p,
        alreadyInSquad,
        cantPickReason: canPick ? undefined : cantPickReason,
        sameAsAlreadyInThisSlot: p.id === bet.picks?.[slotIndex]
      }
      return entry}),
      (item) => {
        console.log("player picked", item, slotIndex)
        changePicks((prev) => {
          const newPicks = _.map(prev, (p) => {
            if (!item) { return p }
            return p === item.id ? undefined : p
          }) // clone and clear out same item from other slots
          newPicks[slotIndex] = item?.id || undefined
          return newPicks
        })
      },
      () => {},
      {
        title: I18n.t(`squadbet.slot${slotIndex}_group_title`),
        picksFor: { slot: slotIndex },
      }
    )
  }

  const pickSub = () => {
    const playerInSlot = squadSlots.squadSlots[BENCH_INDEX]?.player
    const numPickedCaptains = squadSlots.amountPickedCaptains(playerInSlot)

    const playersForSlot = getPlayersFromSlot(bet, 4)
    pickerPickPlayer(_.map(playersForSlot.sort(sortPlayers), (p) => {
      let cantPickReason: string = ""
      let canPick: Boolean = true
      const alreadyInSquad = _.some(squadSlots.allPickedPlayers(), (pl) => pl.id === p.id)
      if (!alreadyInSquad) {
        if (p.captain && numPickedCaptains >= 1) {
          canPick = false
          cantPickReason = I18n.t("squadbet.cant_pick_because_captain")
        } else if (squadSlots.amountPickedPlayersPerTeam(p.team_key) >= 2) {
          canPick = false
          cantPickReason = I18n.t("squadbet.cant_pick_because_team")
        }
      }
      const entry: PickerEntry<SquadBetPlayer> = {
        item: p,
        alreadyInSquad,
        cantPickReason: canPick ? undefined : cantPickReason,
        sameAsAlreadyInThisSlot: p.id === bet.picks?.[BENCH_INDEX]
      }
      return entry}),
      (item) => {
        console.log("sub picked", item)
        changePicks((prev) => {
          const newPicks = _.map(prev, (p) => {
            if (!item) { return p }
            return p === item.id ? undefined : p
          }) // clone and clear out same item from other slots
          newPicks[BENCH_INDEX] = item?.id || undefined
          newPicks[SUB_PLAYER_INDEX] = item?.id || undefined
          return newPicks
        })
      },
      () => {},
      {
        title: I18n.t("squadbet.sub_group_title"),
        picksFor: "sub",
      }
    )
  }

  const pickTeam = () => {
    pickerPickTeam(_.map(_.sortBy(bet.teams, ["team_key"]), (t) => {
      const entry: PickerEntry<SquadBetTeam> = {
        item: t,
        alreadyInSquad: t.id === bet.picks?.[TEAM_INDEX],
        sameAsAlreadyInThisSlot: t.id === bet.picks?.[TEAM_INDEX]
      }
      return entry}),
      (item) => {
        console.log("team picked", item)
        changePicks((prev) => {
          const newPicks = [...prev]
          newPicks[TEAM_INDEX] = item?.id || undefined
          return newPicks
        })
      },
      () => {},
      {
        title: I18n.t("squadbet.team_group_title"),
        picksFor: "team",
      }
    )
  }

  const pickerHasPicksFor = pickerPayload?.additionalConfig?.picksFor

  return <SquadEditor
    openPickerForSlot={{
      slot: [
        () => pickPlayer(0),
        () => pickPlayer(1),
        () => pickPlayer(2),
        () => pickPlayer(3),
      ],
      sub: pickSub,
      team: pickTeam
    }}
    title={I18n.t(`squadbet.squad_title--pick`)}
    titleSideContent={<>
      {!squadSlots.areSlotsBenchTeamCompletelyPicked && <span>{I18n.t("squadbet.edit_mode.info_not_all_slots_picked")}</span>}
      <Button disabled={!squadSlots.areSlotsBenchTeamCompletelyPicked} primary title={I18n.t("squadbet.edit_mode.action_to_view_mode")} onClick={() => setSquadViewMode("view")} />
    </>}
    squadItems={_.map(squadSlots.squadSlots, (s, index) => {
      return s
        ? <NewPickItem
            key={`filled${index}`}
            markSelectedForPicker={isAdditionalConfigForSquadSlot(pickerHasPicksFor) && pickerHasPicksFor.slot === index}
            player={s.player}
            onPick={() => pickPlayer(index)}
            itemFor="player"
            verticalSize="narrow"
            showInlineStats
            lineAboveTitle={I18n.t(`squadbet.picker.title_placeholder_slot${index}`)}
            icon={<NewPickIcon type="edit" />}
          />
        : <NewPickItem
            key={`empty${index}`}
            markSelectedForPicker={isAdditionalConfigForSquadSlot(pickerHasPicksFor) && pickerHasPicksFor.slot === index}
            onPick={() => pickPlayer(index)}
            itemFor="player"
            verticalSize="narrow"
            showInlineStats
            titleIfNoPlayerOrTeam={I18n.t(`squadbet.picker.title_placeholder_slot${index}`)}
            lineAboveTitle={I18n.t("squadbet.picker.pick_intro_line")}
            icon={<NewPickIcon type="pickArrow" />}
          />
    })}
    subItem={squadSlots.benchSlot
      ? <NewPickItem
        markSelectedForPicker={isAdditionalConfigForSubSlot(pickerHasPicksFor)}
        player={squadSlots.benchSlot.player}
        onPick={() => pickSub()}
        itemFor="player"
        verticalSize="narrow"
        showInlineStats
        lineAboveTitle={I18n.t(`squadbet.picker.title_placeholder_sub`)}
        icon={<NewPickIcon type="edit" />}
      />
      : <NewPickItem
          markSelectedForPicker={isAdditionalConfigForSubSlot(pickerHasPicksFor)}
          onPick={() => pickSub()}
          itemFor="player"
          verticalSize="narrow"
          showInlineStats
          titleIfNoPlayerOrTeam={I18n.t(`squadbet.picker.title_placeholder_sub`)}
          lineAboveTitle={I18n.t("squadbet.picker.pick_intro_line")}
          icon={<NewPickIcon type="pickArrow" />}
        />}
    teamItem={squadSlots.teamSlot
      ? <NewPickItem
        markSelectedForPicker={isAdditionalConfigForTeamSlot(pickerHasPicksFor)}
        team={squadSlots.teamSlot.team}
        onPick={() => pickTeam()}
        itemFor="team"
        verticalSize="narrow"
        showInlineStats
        lineAboveTitle={I18n.t(`squadbet.picker.title_placeholder_team`)}
        icon={<NewPickIcon type="edit" />}
      />
      : <NewPickItem
          markSelectedForPicker={isAdditionalConfigForTeamSlot(pickerHasPicksFor)}
          onPick={() => pickTeam()}
          itemFor="team"
          verticalSize="narrow"
          showInlineStats
          titleIfNoPlayerOrTeam={I18n.t(`squadbet.picker.title_placeholder_team`)}
          lineAboveTitle={I18n.t("squadbet.picker.pick_intro_line")}
          icon={<NewPickIcon type="pickArrow" />}
        />}
  />
}

const SelectedSquad: React.FC<{titleSideContent?: React.ReactNode}> = ({titleSideContent}) => {
  const {
    bet,
    squadSlots
  } = useSquadBet()
  const { showStatsForPlayer, showStatsForTeam } = useStatsBoundToBet()

  const titleSuffix = bet.from_other ? "other" : "own"

  return <SquadSection
    title={I18n.t(`squadbet.squad_title--${titleSuffix}`)}
    titleSideContent={titleSideContent}
    squadItems={_.map(squadSlots.squadSlots, (s, index) => {
      return s
        ? <NewPickItem
            key={`filled${index}`}
            player={s.player}
            itemFor="player"
            verticalSize="large"
            showInlineStats
            score={s.score}
            linkStats
            lineAboveTitle={I18n.t(`squadbet.picker.title_placeholder_slot${index}`)}
          />
        : <NewPickItem
            key={`empty${index}`}  
            itemFor="player"
            verticalSize="large"
            titleIfNoPlayerOrTeam={bet.censored ? "?" : "-"}
          />
    })}
    subItem={squadSlots.benchSlot
      ? <NewPickItem
          player={squadSlots.benchSlot.player}
          itemFor="player"
          verticalSize="large"
          score={squadSlots.benchSlot.score}
          showInlineStats
          linkStats
          lineAboveTitle={I18n.t(`squadbet.picker.title_placeholder_sub`)}
        />
      : <NewPickItem
          itemFor="player"
          verticalSize="large"
          titleIfNoPlayerOrTeam={bet.censored ? "?" : "-"}
        />}
    teamItem={squadSlots.teamSlot
      ? <NewPickItem
          team={squadSlots.teamSlot.team}
          itemFor="team"
          verticalSize="large"
          score={squadSlots.teamSlot.score}
          linkStats
          lineAboveTitle={I18n.t(`squadbet.picker.title_placeholder_team`)}
        />
      : <NewPickItem
          itemFor="team"
          verticalSize="large"
          titleIfNoPlayerOrTeam={bet.censored ? "?" : "-"}
        />}
  />
}

const SquadOrScoringForDay: React.FC = () => {
  const {
    bet,
    squadSlots
  } = useSquadBet()

  const titleSuffix = bet.from_other ? "other" : "own"

  return <SquadSection
    title={I18n.t(`squadbet.squad_title--${titleSuffix}`)}
    squadItems={_.map(squadSlots.squadSlots, (s, index) => {
      return s
        ? <NewPickItem
            key={`filled${index}`}
            markGreyedOut={_.isBoolean(s.scoreCounts) && !s.scoreCounts}
            player={s.player}
            itemFor="player"
            verticalSize="large"
            showInlineStats
            score={s.score}
            linkStats
            lineAboveTitle={I18n.t(`squadbet.picker.title_placeholder_slot${index}`)}
          />
        : <NewPickItem
            key={`empty${index}`}
            markGreyedOut
            itemFor="player"
            verticalSize="large"
            titleIfNoPlayerOrTeam={bet.censored ? "?" : "-"}
          />
    })}
    subItem={squadSlots.benchSlot
      ? <NewPickItem
          player={squadSlots.benchSlot.player}
          itemFor="player"
          markGreyedOut
          verticalSize="large"
          score={squadSlots.benchSlot.score}
          showInlineStats
          linkStats
          lineAboveTitle={I18n.t(`squadbet.picker.title_placeholder_sub`)}
        />
      : <NewPickItem
          itemFor="player"
          markGreyedOut
          verticalSize="large"
          titleIfNoPlayerOrTeam={bet.censored ? "?" : "-"}
        />}
    teamItem={squadSlots.teamSlot
      ? <NewPickItem
          team={squadSlots.teamSlot.team}
          markGreyedOut={!_.isNumber(bet.total_score)}
          itemFor="team"
          verticalSize="large"
          score={squadSlots.teamSlot.score}
          linkStats
          lineAboveTitle={I18n.t(`squadbet.picker.title_placeholder_team`)}
        />
      : <NewPickItem
          markGreyedOut
          itemFor="team"
          verticalSize="large"
          titleIfNoPlayerOrTeam={bet.censored ? "?" : "-"}
        />}
  />
}

const SelectSub: React.FC = () => {
  const {
    changePicks,
    squadSlots,
    bet,
  } = useSquadBet()

  const unSwap = (index: number) => {
    console.log("unSwap", index)
    changePicks((prev) => {
      const newPicks = [...prev]
      newPicks[index] = squadSlots.benchSlot?.player.id
      newPicks[BENCH_INDEX] = squadSlots.substitutePlayer?.id
      return newPicks
    })
  }

  const swapIn = (index: number) => {
    console.log("swapIn", index)
    changePicks((prev) => {
      const newPicks = [...prev]
      newPicks[BENCH_INDEX] = prev[index]
      newPicks[index] = squadSlots.substitutePlayer?.id
      return newPicks
    })
  }

  const swappedInPlayerIndex = _.findIndex(squadSlots.squadSlots, (s) => {
    return !!(s && s.isSub)
  })

  return (squadSlots.substitutePlayer && _.compact(squadSlots.squadSlots).length > 0)
    ? <SquadSection
        squadItems={_.map(squadSlots.squadSlots, (s, index) => {
          const subAction: {type: "swapIn" | "unSwap",text: string, action(): void} | undefined =
            squadSlots.substituteSwappedIn
            ? (s
              ? (s.isSub
                ? {type: "unSwap", text: I18n.t("squadbet.action_swap"), action: () => unSwap(index)}
                : undefined)
              : undefined)
            : {type: "swapIn", text: I18n.t("squadbet.action_swap"), action: () => swapIn(index)}
                            
          const swapButton: NewPickItemProps["buttonInImageContainer"] = subAction
            ? { onClick: subAction.action, text: subAction.text, iconUrl: swapImgUrl }
            : undefined

          return s
            ? <NewPickItem
                key={`filled${index}`}
                player={s.player}
                itemFor="player"
                verticalSize="large"
                showInlineStats
                score={s.score}
                buttonInImageContainer={swapButton}
                linkStats
              />
            : <NewPickItem
                key={`empty${index}`}
                itemFor="player"
                buttonInImageContainer={swapButton}
                verticalSize="large"
                titleIfNoPlayerOrTeam={bet.censored ? "?" : "-"}
              />
        })}
        subItem={squadSlots.benchSlot
          ? <NewPickItem
              player={squadSlots.benchSlot.player}
              itemFor="player"
              markGreyedOut
              verticalSize="large"
              score={squadSlots.benchSlot.score}
              buttonInImageContainer={squadSlots.substituteSwappedIn && _.isNumber(swappedInPlayerIndex) && swappedInPlayerIndex !== -1
                ? {
                    onClick: () => {unSwap(swappedInPlayerIndex)},
                    iconUrl: swapImgUrl,
                    text: I18n.t("squadbet.action_swap"),
                  }
                : undefined
              }
              showInlineStats
              linkStats
            />
          : <NewPickItem
              itemFor="player"
              markGreyedOut
              verticalSize="large"
              titleIfNoPlayerOrTeam={bet.censored ? "?" : "-"}
            />}
        teamItem={squadSlots.teamSlot
          ? <NewPickItem
              team={squadSlots.teamSlot.team}
              itemFor="team"
              verticalSize="large"
              score={squadSlots.teamSlot.score}
              linkStats
              lineAboveTitle={I18n.t(`squadbet.picker.title_placeholder_team`)}
            />
          : <NewPickItem
              itemFor="team"
              verticalSize="large"
              titleIfNoPlayerOrTeam={bet.censored ? "?" : "-"}
            />}
      />
    : <>
        <div className="squadBet__pickSub__cantBecauseNoNecessaryPlayers">
          {I18n.t("squadbet.cant_pick_sub_because_not_necessary_players")}
        </div>
      </>
}

const UpcomingOrNotingPickedInfo: React.FC = () => {
  const {
    bet,
    squadSlots
  } = useSquadBet()

  const dayNumber = bet.day_index + 1

  return <>
    <div className="squadBet__upcoming__info">
      {bet.from_other
        ? I18n.t("squadbet.upcoming.other_user")
        : (!betStatus.isMatchOpen(bet) && !squadSlots.madeAnyPicks
          ? I18n.t("squadbet.upcoming.nothing_picked") // should only occur when first day missed
          : I18n.t(`squadbet.upcoming.day${dayNumber}_info`))}
    </div>
  </>
}

const _SquadBet: React.FC = () => {
  const {
    bet,
    squadSlots,
    displaySaveResult,
    authToken,
    squadViewMode,
    setSquadViewMode,
  } = useSquadBet()

  const [ viewIsUnfolded, setViewIsUnfolded ] = useState(
    // unfold initially for open and live bets
    (betStatus.isMatchOver(bet) && bet.day_index === 0) || betStatus.isMatchOpen(bet) || betStatus.isMatchInProgress(bet)
  )

  return <WithStats>
    {bet.day_index === 0 && betStatus.isMatchOpen(bet) && !betStatus.isFromOther(bet) &&
      <div className="layout__contentRow">
        <div className="infoBox infoBox--strong">
          <div className="infoBox__content">
            {I18n.t("squadbet.requirement_info")}
          </div>
        </div>
      </div>}
    <div className="squadBet">
      {bet.interaction_mode === "select-squad" &&
        (betStatus.canEdit(bet)
          ? <>
              <DisplaySaveResult classPrefix="squadBet" saveResult={displaySaveResult} />
              {squadViewMode === "edit"
                ? <>
                    <WithPicker detachView>
                      <SelectSquad />
                    </WithPicker>
                  </>
                : <>                  
                    <SelectedSquad
                      titleSideContent={
                        <Button primary title={I18n.t("squadbet.edit_mode.action_to_edit_mode")} onClick={() => setSquadViewMode("edit")} />
                      } />
                  </>}
            </>
          : <><SelectedSquad /></>)}
      {bet.tiebreaker_question && (
        <TiebreakerQuestion
          round={bet.round}
          question={bet.tiebreaker_question}
          answer={bet.tiebreaker_value}
          authToken={authToken}
          canEdit={betStatus.canEdit(bet)}
        />
      )}
      <div className="squadBet__dayWrapper">
        <DayHeader
          hideViewUnfoldedToggler={
            (!squadSlots.madeAnyPicks && betStatus.canEdit(bet))
            || (bet.interaction_mode === "select-squad" && betStatus.canEdit(bet))
            || (bet.interaction_mode === "select-squad" && !squadSlots.madeAnyPicks)
            || (bet.interaction_mode === "select-substitute" && !squadSlots.madeAnyPicks)
            || !!bet.postponed
            || !!bet.cancelled}
          viewIsUnfolded={viewIsUnfolded}
          toggleViewIsUnfolded={() => setViewIsUnfolded(prev => !prev)} />
        {bet.cancelled || bet.postponed
          ? <>
              <CancelledInfo />
              <PostponedInfo />
            </>
          : (!betStatus.isMatchOpen(bet) && !bet.from_other && !squadSlots.madeAnyPicks
              ? <UpcomingOrNotingPickedInfo /> // own closed without picks
              : <>
                  {bet.interaction_mode === "select-substitute" &&
                    (squadSlots.madeAnyPicks
                      ? (betStatus.canEdit(bet)
                        ? viewIsUnfolded && <>
                            <DisplaySaveResult classPrefix="squadBet" saveResult={displaySaveResult} />
                            <SelectSub />
                          </>
                        : viewIsUnfolded && <SquadOrScoringForDay />)
                      : <UpcomingOrNotingPickedInfo />)}
                  {bet.interaction_mode === "select-squad" &&
                    (betStatus.isMatchOpen(bet)
                      ? <UpcomingOrNotingPickedInfo />
                      : viewIsUnfolded && <SquadOrScoringForDay />)}
                </>)}
      </div>
    </div>
  </WithStats>
}

export const BetStatusWithCountdown: React.FC<{bet: BaseBet}> = ({bet}) => {
  addSecondsLeftToBet(bet)

  const betWithSecondsLeft = bet as WithSecondsLeft<typeof bet>

  return <div className="squadBet__betStatusComp">
    <div className="squadBet__betStatusComp__date">
      {betStatus.prettyDateOrCancelledOrTBD(betWithSecondsLeft, "MMM D, HH:mm")}
    </div>
    {betStatus.isMatchOpen(betWithSecondsLeft)
      ? <div className="squadBet__betStatusComp__status">{countdownSegmentsToComp(unwrapSec(betWithSecondsLeft.secondsLeft))}</div>
      : (betStatus.isMatchInProgress(betWithSecondsLeft)
        ? <div className="squadBet__betStatusComp__status squadBet__betStatusComp__status--live">{I18n.t("round_summary.state_live")}</div>
        : <></>)}
  </div>
}

interface Props<T extends TSquadBet> {
  bet: T
  authToken: string
}

const SquadBet = <T extends TSquadBet>({ bet, authToken }: Props<T>) => {
  return <WithSquadBet authToken={authToken} initialBet={bet}>
    <_SquadBet />
  </WithSquadBet>
}

export default SquadBet
