import React, { useCallback, useRef, useState } from "react"
import Modal from "react-modal"
import I18n from "i18n-js"

import Button from "./Button"

type FormRowProps = React.PropsWithChildren<{
  label?: string
  second?: boolean
  subLabel?: string
  center?: boolean
}>

const FormRow = ({ label, children, second, subLabel, center }: FormRowProps) => {
  const secondClass = (second) ? " changeCommunitySettings__row--second" : ""
  const centerClass = (center) ? " changeCommunitySettings__row--center" : ""
  return (
    <div className={"changeCommunitySettings__row" + secondClass + centerClass}>
      <div className="changeCommunitySettings__labelContainer">
        <div className="changeCommunitySettings__label">{label}</div>
        {subLabel && <div className="changeCommunitySettings__subLabel">{subLabel}</div>}
      </div>
      <div className="changeCommunitySettings__input">
        {children}
      </div>
    </div>
  )
}

interface FormRadioProps {
  id: string
  label: string
  subLabel?: string
  iconClass?: string
  value: string | number
  checked: boolean
  name: string
}

const FormRadio = ({ id, label, subLabel, iconClass, value, checked, name }: FormRadioProps) => {
  return (
    <div className="radio changeCommunitySettingsTable__radio pointerForAllChildren">
      <input id={id} className="radio__input" type="radio" value={value} defaultChecked={checked} name={name}/>
      <label className="radio__label" htmlFor={id}>
        <div className={`radio__title ${iconClass && ("radio__title--icon icon " + iconClass)}`}>
          {label}
        </div>
        {subLabel && <div className="radio__title radio__title--sub">
          {subLabel}
        </div>}
      </label>
    </div>
  )
}

interface Member {
  id: number
  role: "member" | "moderator" | "admin"
}

interface Props {
  id: string
  member: Member
  member_action: string
  authenticity_token: string
  edit_icon: string
}

Modal.setAppElement("#neoApp")

const EditMemberModal = ({
  id,
  member,
  member_action: memberAction,
  authenticity_token: authenticityToken,
  edit_icon: editIcon,
}: Props) => {
  const [isOpen, setOpen] = useState(false)

  const [role, setRole] = useState(member.role)

  const formRef = useRef<HTMLFormElement>(null)

  const closeModal = useCallback(() => {
    setOpen(false)
  }, [])

  const openModal = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <>
      <Button icon={editIcon} onClick={openModal} onlyIcon />
      {/* Issue seems to stem from ts support of the package
      @ts-ignore-next-line */}
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        overlayClassName="neo__modalOverlay"
        className="neo__modalContent"
        parentSelector={() => document.getElementById("neoApp")!}
      >
        <div className="changeCommunitySettings">
          <div className="changeCommunitySettings__header">
            <h2 className="changeCommunitySettings__title">{I18n.t("community.edit.title")}</h2>
            <div className="changeCommunitySettings__icon icon pointer" onClick={closeModal} />
          </div>
          <div className="changeCommunitySettings__main">
            <form ref={formRef} action={memberAction} className="changeCommunitySettings__main" acceptCharset="utf-8" method="post" id={`Edit${id}`}>
              <input type="hidden" name="_method" value="patch" />
              <input type="hidden" name="authenticity_token" value={authenticityToken} />
              <FormRow>
                <FormRadio
                  id={`roleMember${member.id}`}
                  label={I18n.t("general.role.regular_member")}
                  value="member"
                  checked={role === "member"}
                  name="role"
                />
                <FormRadio
                  id={`roleModerator${member.id}`}
                  label={I18n.t("general.role.moderator")}
                  subLabel={I18n.t("community.edit.moderator.description")}
                  value="moderator"
                  checked={role === "moderator"}
                  name="role"
                />
                <FormRadio
                  id={`roleAdmin${member.id}`}
                  label={I18n.t("general.role.admin")}
                  subLabel={I18n.t("community.edit.admin.description")}
                  value="admin"
                  checked={role === "admin"}
                  name="role"
                />
              </FormRow>
              <FormRow center>
                <Button
                  primary
                  className="changeCommunitySettings__button"
                  onClick={() => formRef.current?.submit()}
                >
                  {I18n.t("community_settings.save")}
                </Button>
              </FormRow>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default EditMemberModal