import React, { PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from "react"
import { SquadBetPlayer, SquadBetTeam } from "./types"
import _ from "lodash"
import useResize from "use-resize"
import { NewPickIcon, NewPickItem, PlayerItemInner, TeamItemInner } from "./ui"
import I18n from "i18n-js"
import { useSquadBet } from "./context"
import { useDevice } from "@root/misc/device"
import { disableBodyScroll, enableBodyScroll } from "@root/misc/disableBodyScroll"
import { isClearSlot, SquadSlots } from "./helper"

export interface PickerEntry<ItemType> {
  item: ItemType
  alreadyInSquad?: boolean
  sameAsAlreadyInThisSlot?: boolean
  cantPickReason?: string
}

type PickerFn<ItemType> = (entries: PickerEntry<ItemType>[], onPick: (item?: ItemType) => void, onAbort?: () => void, additionalConfig?: PickerViewAdditionalConfig) => void

type PickerViewIsForSquadSlot = {slot: number}
type PickerViewIsForSub = "sub"
type PickerViewIsForTeam = "team"

interface PickerViewAdditionalConfig {
  title?: string,
  picksFor?: PickerViewIsForSquadSlot | PickerViewIsForSub | PickerViewIsForTeam
}

const PickerContext = createContext<{
  closePicker(): void
  pickPlayer: PickerFn<SquadBetPlayer>
  pickTeam: PickerFn<SquadBetTeam>
  viewPayload?: PickerPayload
  viewIsVisible: boolean
}>({pickPlayer: () => {}, pickTeam: () => {}, viewIsVisible: false, closePicker: () => {}})

type PickerPayload =
  | {
      type: "player"
      entries: PickerEntry<SquadBetPlayer>[]
      onPick(item?: SquadBetPlayer): void
      onAbort?(): void
      additionalConfig?: PickerViewAdditionalConfig
    }
  | {
      type: "team"
      entries: PickerEntry<SquadBetTeam>[]
      onPick(item?: SquadBetTeam): void
      onAbort?(): void
      additionalConfig?: PickerViewAdditionalConfig
    }

export const isAdditionalConfigForSquadSlot = (conf: any): conf is PickerViewIsForSquadSlot => {
  return conf && _.isNumber((conf as PickerViewIsForSquadSlot).slot)
}

export const isAdditionalConfigForSubSlot = (conf: any): conf is PickerViewIsForSub => {
  return conf === "sub"
}

export const isAdditionalConfigForTeamSlot = (conf: any): conf is PickerViewIsForTeam => {
  return conf === "team"
}

export const ScrollablePickerInnerView: React.FC<{numCols: number, afterPlayerPick?(): void, afterTeamPick?(): void}> = ({numCols, afterPlayerPick, afterTeamPick}) => {
  const { viewPayload: payload, closePicker } = useContext(PickerContext)
  const { bet, squadSlots } = useSquadBet()
  const squadSlotsRef = useRef(squadSlots)
  squadSlotsRef.current = squadSlots

  if (!payload) { return null }

  return  <div className={`squadBet__picker__inner squadBet__picker__inner--${numCols}cols`}>
    {payload.type === "team" && _.map(payload.entries, (entry) => {
      // ------ TEAM ------
      return <div key={entry.item.id}>
        <NewPickItem      
          onPick={() => {payload.onPick(entry.item); closePicker(); window.setTimeout(() => afterTeamPick?.(), 20)}}
          team={entry.item}
          itemFor="team"
          linkStats
          markGreyedOut={entry.alreadyInSquad}
          icon={<NewPickIcon type={entry.alreadyInSquad ? "check" : "plus"} />}
          verticalSize="narrow" />
       </div>
      })}
    {payload.type === "player" && (() => {
      // ------ PLAYER ------
      const entriesWithClear: ("clearSlot" | PickerEntry<SquadBetPlayer>)[] = [/* "clearSlot" ,*/ ...payload.entries]
      const perCol = Math.ceil(entriesWithClear.length / numCols)
      return _.times(numCols, (colIndex) => {
        return <div key={colIndex}>
          {_.map(entriesWithClear.slice(colIndex * perCol, (colIndex + 1) * perCol), (entry) => {
            if (isClearSlot(entry)) {
              return null // not used!
            } else {
              return <NewPickItem key={entry.item.id}
                warning={entry.cantPickReason}
                markGreyedOut={!!(entry.cantPickReason || entry.alreadyInSquad)}
                player={entry.item}
                linkStats
                showInlineStats
                onPick={entry.cantPickReason ? undefined : () => {payload.onPick(entry.item); closePicker(); window.setTimeout(() => afterPlayerPick?.(), 20)}}
                itemFor="player"
                verticalSize="narrow"
                icon={<NewPickIcon type={entry.alreadyInSquad ? "check" : "plus"} />} />
            }
          })}
        </div>
      })
    })()}
  </div>
}

export const PickerView: React.FC = () => {
  const { viewPayload: payload, closePicker } = useContext(PickerContext)
  const { height: windowHeight } = useResize()
  const { isPhone, isDesktopUp } = useDevice()

  const maxPickerHeight = Math.floor(windowHeight * .8)

  const numCols = isPhone
    ? 2
    : (isDesktopUp ? 4 : 3)

  useEffect(() => {
    if (!!payload) {
      disableBodyScroll()
    } else {
      enableBodyScroll()
    }
  }, [!!payload])

  useEffect(() => { // make sure body can scroll when picker unmounted (e.g. when countdown runs out and bet closes itself)
    return () => { // cleanup
      enableBodyScroll()
    }
  }, [])

  if (!payload) { return <></> }

  return <>
    <div className="squadBet__picker__backdrop" />
    <div className="squadBet__picker__holder" onClick={closePicker}>
      <div className="squadBet__picker__positioner" onClick={(evt) => evt.stopPropagation()}>
        <div className="squadBet__picker">
          <a className="squadBet__picker__close" onClick={closePicker}>{I18n.t("squadbet.action_close_picker")}</a>
          <div className="squadBet__picker__innerHolder" style={{maxHeight: `${maxPickerHeight}px`}}>
            <ScrollablePickerInnerView numCols={numCols} />
          </div>
        </div>
      </div>
    </div>
  </>
}

export const WithPicker: React.FC<PropsWithChildren<{detachView?: boolean}>> = ({children, detachView}) => {
  const [payload, setPayload] = useState<PickerPayload>()
  const closePicker = () => setPayload(undefined)

  return <PickerContext.Provider value={{
    pickPlayer: (entries, onPick, onAbort, additionalConfig) => {
      setPayload({type: "player", entries, onPick, onAbort, additionalConfig})
    },
    pickTeam: (entries, onPick, onAbort, additionalConfig) => {
      setPayload({type: "team", entries, onPick, onAbort, additionalConfig})
    },
    closePicker,
    viewPayload: payload,
    viewIsVisible: !!payload
  }}>
    {payload && !detachView && <PickerView />}
    {children}
  </PickerContext.Provider>
}

const usePicker = () => useContext(PickerContext)

export default usePicker
