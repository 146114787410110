import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import { BetId, SquadBetPlayer, SquadBetTeam } from "./types"
import _ from "lodash"
import useResize from "use-resize"
import { PlayerItemInner, TeamItemInner } from "./ui"
import I18n from "i18n-js"
import { useSquadBet } from "./context"
import axios from "axios"
import { disableBodyScroll, enableBodyScroll } from "@root/misc/disableBodyScroll"
import { displayPoints } from "@root/helpers/number_helpers"

const StatsContext = createContext<{showStats(payload: StatsPayload): void}>({showStats: () => {}})

type StatsPayload = {
  betId: BetId
  player?: SquadBetPlayer
  team?: SquadBetTeam
}

export interface PlayerStats {
  avg_score: number // Float (prec: 2)
  avg_driving_distance: number // Float (prec: 2)
  fairway_hit_percent: number // Float (prec: 2)
  greens_in_regulation_percent: number // Float (prec: 2)
  scrambling_percent: number // Float (prec: 2)
  avg_putts_per_hole: number // Float (prec: 2)
}

export interface PlayerStatsForBet {
  round?: PlayerStats // exists if Round has started
  global: PlayerStats
  score_history: {
    round_name: string
    score: number
  }[]
}

export const usePlayerStats = (player_id: SquadBetPlayer["id"], bet_id: BetId) => {
  const [playerStats, setPlayerStats] = useState<PlayerStatsForBet | null>(null)
  const [error, setError] = useState<any | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const { authToken: authenticity_token } = useSquadBet()
  useEffect(() => {
    setLoading(true)
    axios.post<PlayerStatsForBet>(`/bet/stats`, { authenticity_token, player_id, bet_id })
      .then((res) => {
        if (res.status === 200 && res.data) {
          setPlayerStats(res.data)
        }
        setLoading(false)
      }).catch((error) => {
        setError(error)
        setLoading(false)
      })
  }, [])
  return [playerStats, error, loading] as [PlayerStatsForBet|null, any|null, boolean]
}

const LoadingIndicator = () => {
  return <>
    <div className="playerStats__loadingIndicatorContainer">
      <svg className="playerStats__loadingIndicator">
        <path d="M 25 0 A 25 25 90 0 1 50 25"></path>
      </svg>
    </div>
  </>
}

const StatsComponent: React.FC<{playerId: SquadBetPlayer["id"], betId: BetId}> = ({ playerId, betId }) => {

  const [playerStats, error, loading] = usePlayerStats(playerId, betId)
  const [tab, setTab] = useState<"round" | "global">("global")

  if (playerStats === null || error) {
    return <div className="playerStats__popup__inner__gridStatsContainer">
      <div className="playerStats__popup__inner__statsTitle">{I18n.t("squadbet.stats.title")}</div>
      {error
        ? <div className="playerStats__popup__inner__errorMessage">{error.toString?.()}</div>
        : loading
          ? <LoadingIndicator />
          : <div className="playerStats__popup__inner__errorMessage">Player stats not found.</div>
      }
    </div>
  }

  const tabClass = {
    global: ["playerStats__popup__inner__statsTab", tab === "global" ? "playerStats__popup__inner__statsTab--selected" : ""].join(" "),
    round: ["playerStats__popup__inner__statsTab", tab === "round" ? "playerStats__popup__inner__statsTab--selected" : ""].join(" ")
  }

  return <>
    <div className="playerStats__popup__inner__gridStatsContainer">
      <div className="playerStats__popup__inner__statsTitle">{I18n.t("squadbet.stats.title")}</div>
      <div className="playerStats__popup__inner__statsTabBar">
        <div className={tabClass.global} onClick={() => setTab("global")}>Global</div>
        {playerStats.global && <div className={tabClass.round} onClick={() => setTab("round")}>Round</div>}
      </div>
      { tab === "global" && <div className="playerStats__popup__inner__gridStatsSubContainer">
        {Object.entries(playerStats.global).map(([key, value]) => (<React.Fragment key={key}>
          <div className="playerStats__popup__inner__label">{I18n.t(`squadbet.stats.${key}.label`)}</div>
          <div className="playerStats__popup__inner__value">{I18n.t(`squadbet.stats.${key}.value`, { value })}</div>
        </React.Fragment>))}
      </div>}
      { playerStats.round && tab === "round" &&
        <div className="playerStats__popup__inner__gridStatsSubContainer">
          {Object.entries(playerStats.round).map(([key, value]) => (<React.Fragment key={key}>
            <div className="playerStats__popup__inner__label">{I18n.t(`squadbet.stats.${key}.label`)}</div>
            <div className="playerStats__popup__inner__value">{I18n.t(`squadbet.stats.${key}.value`, { value })}</div>
          </React.Fragment>))}
        </div>
      }
    </div>
    {playerStats.score_history.length > 0 && <div className="playerStats__popup__inner__gridStatsHistoryContainer">
      <div className="playerStats__popup__inner__gridStatsSubHeader">{I18n.t("squadbet.stats.score_history.title")}</div>
      <div className="playerStats__popup__inner__gridStatsHistory">
        {playerStats.score_history.map(({round_name, score}) => (<React.Fragment key={round_name}>
          <div className="playerStats__popup__inner__label">{round_name}</div>
          <div className="playerStats__popup__inner__value">{displayPoints(score)}</div>
        </React.Fragment>))}
      </div>
    </div>}
  </>
}

const PlayerStatsInner: React.FC<{player: SquadBetPlayer, betId: BetId}> = ({player, betId}) => {

  return <div className="playerStats__popup__inner__gridContainer">
     <div className="playerStats__popup__inner__grid">
      <div className="playerStats__popup__inner__image">
        <PlayerItemInner player={player} />
      </div>
      <div className="playerStats__popup__inner__gridNameContainer">
        {player.name}
      </div>
      <StatsComponent playerId={player.id} betId={betId} />
    </div>
  </div>
}

// TODO: Remove?
// const TeamStatsInner: React.FC<{team: SquadBetTeam, betId: BetId}> = ({team}) => {
//   return <div className="playerStats__popup__inner__gridContainer">
//     <div className="playerStats__popup__inner__grid">
//       <div className="playerStats__popup__inner__image">
//         <TeamItemInner team={team} />
//       </div>
//       <div className="playerStats__popup__inner__gridNameContainer">
//         {team.name}
//       </div>
//       <div className="playerStats__popup__inner__gridStatsContainer">
//         <div className="playerStats__popup__inner__statsTitle">{I18n.t("squadbet.action_show_player_stats")}</div>
//         <div className="playerStats__popup__inner__label">{I18n.t("squadbet.stat_team_rank_label")}</div>
//         <div className="playerStats__popup__inner__value">{team.stats ? I18n.t("squadbet.stat_team_rank", {rank: team.stats.rank, total: team.stats.total_ranks}) : "N/A"}</div>
//         <div className="playerStats__popup__inner__label">{I18n.t("squadbet.stat_picked_by_label")}</div>
//         <div className="playerStats__popup__inner__value">{team.stats ? `${team.stats.perc_picked}%` : "N/A"}</div>
//       </div>
//     </div>
//   </div>
// }

export const WithStats: React.FC<PropsWithChildren<{}>> = ({children}) => {
  const [payload, setPayload] = useState<StatsPayload>()
  const {height: windowHeight} = useResize()

  const maxInnerHeight = Math.floor(windowHeight * .8)

  useEffect(() => {
    if (!!payload) {
      disableBodyScroll()
    } else {
      enableBodyScroll()
    }
  }, [!!payload])
  const closePopup = () => setPayload(undefined)

  useEffect(() => { // make sure body can scroll when picker unmounted (e.g. when countdown runs out and bet closes itself)
    return () => { // cleanup
      enableBodyScroll()
    }
  }, [])

  return <StatsContext.Provider value={{
    showStats: (p) => {
      setPayload(p)
    }
  }}>
    {payload && <>
      <div className="playerStats__popup__backdrop" />
      <div className="playerStats__popup__holder" onClick={closePopup}>
        <div className="playerStats__popup__positioner" onClick={(evt) => evt.stopPropagation()}>
          <div className="playerStats__picker">
            <a className="playerStats__popup__close" onClick={closePopup}>{I18n.t("squadbet.action_close_picker")}</a>
            <div className="playerStats__popup__inner" style={{maxHeight: `${maxInnerHeight}px`}}>
              {payload.player &&
                <PlayerStatsInner key={`player-${payload.player.id}-${payload.betId}`} player={payload.player} betId={payload.betId} />}
              {/* {payload.team &&
                <TeamStatsInner key={`team-${payload.team.id}-${payload.betId}`} team={payload.team} betId={payload.betId} />} */}
            </div>
          </div>
        </div>
      </div></>}
    {children}
  </StatsContext.Provider>
}

const useStats = () => useContext(StatsContext)

export const useStatsBoundToBet = () => {
  const { showStats } = useStats()
  const { bet } = useSquadBet()

  return {
    showStatsForPlayer(player: SquadBetPlayer) { showStats({player, betId: bet.bet_id}) },
    showStatsForTeam(team: SquadBetTeam) { showStats({team, betId: bet.bet_id}) }
  }
}

export default useStats
