import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import I18n from 'i18n-js'
import Button from './Button'

import modalSaveZone from '../misc/modal_save_zone'

function CommunityJoinModal ({
  auth_endpoints: authEndpoints,
  login_path: loginPath,
  signup_path: signupPath,
  community_name: communityName
}) {
  const [isOpen, setIsOpen] = useState(true)
  const [ saveZone, setSaveZone ] = useState(null)

  useEffect(() => {
    modalSaveZone.onReady(() => {
      console.log('setSaveZone')
      setSaveZone(modalSaveZone.calculate())
    })
  }, [false])

  if (!saveZone) return (null)

  const closeModal = () => setIsOpen(false)

  const style = {
    // width: `${Math.min(400, saveZone.width)}px`,
    top: `${saveZone.centerY}px`,
    left: `${saveZone.centerX}px`,
    transform: `translate(-50%, -50%)`
  }

  /** @param {MouseEvent} e */
  const handleLogin = (e) => {
    e.preventDefault()
    __Neo.gigya.login()
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: style
      }}
      appElement={document.querySelector('#neoApp')}
      parentSelector={() => document.getElementById("neoApp")}
      overlayClassName="neo__modalOverlay"
      onRequestClose={closeModal}
      className='invitedModal'>
      <div className='invitedModal__close' onClick={closeModal} />
      <div className='invitedModal__header'>
        <h2 className='invitedModal__title'>
          {I18n.t('community.modal.join.title', { community_name: communityName })}
        </h2>
      </div>
      <div className='invitedModal__body'>
        <p className='invitedModal__text'>
          {I18n.t('community.modal.join.text')}
        </p>
        <Button className='invitedModal__button' primary href={signupPath}>
          {I18n.t('community.modal.join.button')}
        </Button>
        <span className='invitedModal__haveAccount'>
          {I18n.t('community.modal.welcome.have_account')} <a onClick={handleLogin}>{I18n.t('community.modal.welcome.login')}</a>
        </span>
      </div>
    </Modal>
  )
}

export default CommunityJoinModal
